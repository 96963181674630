// MeasurementCard.js
import React from "react";

type MeasurementCardProps = {
  imgURL: string;
  altText?: string;
  className?:string
};

const MeasurementCard: React.FC<MeasurementCardProps> = ({
  imgURL,
  altText = "",
  className
}) => {
  return (
    <div
      className={`w-full h-full flex items-center justify-center px-12 py-16 bg-[#F7F8FA]`}
    >
      <img src={imgURL} alt={altText} className={className} />
    </div>
  );
};

export default MeasurementCard;
