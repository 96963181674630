import { useState, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import clsx from "clsx";
import axios from "axios";
import RootPage from "./routes/root";
import ErrorPage from "./routes/errorpage/errorpage";
import LandingPage from "./routes/landingpagev2/LandingPagev";
import TermsPage from "./routes/termsPage/TermsPage";
import AccessibilityPage from "./routes/accessibilityPage/AccessibilityPage";
import { navLinks } from "./routes/header/utils/navligationLinkLists";
import splitLocationString from "./routes/header/utils/getLocation";
import { useAccount, useMsal } from "@azure/msal-react";
import { styleSheet } from "./css";

const ContactPage = lazy(() => import("./routes/contactPage/contactPage"));
const FoundationsPage = lazy(
  () => import("./routes/foundationsPage/foundationsPage")
);
const FoundationsDetailPage = lazy(
  () => import("./routes/foundationsPage/foundationsDetailPage")
);
const DesignPage = lazy(() => import("./routes/designPage/designPage"));
const DesignDetailPage = lazy(
  () => import("./routes/designPage/designDetailPage")
);
const ComponentsPage = lazy(
  () => import("./routes/componentPage/componentPage")
);
const ComponentsDetailPage = lazy(
  () => import("./routes/componentPage/componentDetailPage")
);
const DevelopmentPage = lazy(
  () => import("./routes/developmentPage/developmentPage")
);
const DevDetailPage = lazy(
  () => import("./routes/developmentPage/devDetailPage")
);
const ResourcesPage = lazy(
  () => import("./routes/resourcesPage/resourcesPage")
);
const ResourcesDetailPage = lazy(
  () => import("./routes/resourcesPage/resourcesDetailPage")
);

const PrivacyPage = lazy(() => import("./routes/privacyPage/PrivacyPage"));

const checkSlug = (slug: string, index: number): boolean => {
  for (const item of navLinks[index].subMenu) {
    if (item?.children) {
      for (const child of item?.children) {
        if (splitLocationString(child.to?.substring(1) || "") === slug) {
          //found slug : return error false;
          return false;
        }
      }
    } else {
      if (splitLocationString(item.to?.substring(1) || "") === slug) {
        //found slug : return error false;
        return false;
      }
    }
  }
  return true;
};

interface AppProps {
  msalInstance: any;
}

const App = ({ msalInstance }: AppProps) => {
  const [isOpen, setOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const logoutUser: () => Promise<void> = async () => {
    try {
      const logoutRequest = {
        account: instance.getAccountByHomeId(account?.homeAccountId as string),
        postLogoutRedirectUri: "/",
      };
      const response = await axios(
        `${window.location.origin}/uds-doc/auth/logout`
      );
      if (response?.data?.message === "success") {
        instance.logoutRedirect(logoutRequest);
      }
    } catch (error) {
      console.log("There was an error with logout :", error);
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RootPage
          isOpen={isOpen}
          setOpen={setOpen}
          msalInstance={msalInstance}
          logOutUser={logoutUser}
        />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <LandingPage logOutUser={logoutUser} />,
        },
        {
          path: "/foundations",
          element: <FoundationsPage />,
        },
        {
          path: "/foundations/:slug",
          loader: ({ params }): any => {
            if (checkSlug(params.slug || "", 0)) {
              throw new Error("Not Found");
            }

            return null;
          },
          element: <FoundationsDetailPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/design",
          element: <DesignPage />,
        },
        {
          path: "/design/:slug",
          loader: ({ params }): any => {
            if (checkSlug(params.slug || "", 1)) {
              throw new Error("Not Found");
            }

            return null;
          },
          element: <DesignDetailPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/development",
          element: <DevelopmentPage />,
        },
        {
          path: "/development/:slug",
          loader: ({ params }): any => {
            if (checkSlug(params.slug || "", 2)) {
              throw new Error("Not Found");
            }

            return null;
          },
          element: <DevDetailPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/components",
          element: <ComponentsPage />,
        },
        {
          path: "/components/:slug",
          loader: ({ params }): any => {
            if (checkSlug(params.slug || "", 3)) {
              throw new Error("Not Found");
            }

            return null;
          },
          element: <ComponentsDetailPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
        {
          path: "/resources",
          element: <ResourcesPage />,
        },
        {
          path: "/resources/:slug",
          loader: ({ params }): any => {
            if (checkSlug(params.slug || "", 4)) {
              throw new Error("Not Found");
            }

            return null;
          },
          element: <ResourcesDetailPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/privacy",
          element: <PrivacyPage />,
        },
        {
          path: "/terms",
          element: <TermsPage />,
        },
        {
          path: "/accessibility",
          element: <AccessibilityPage />,
        },
      ],
    },
  ]);
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: styleSheet() }} />
      <div
        data-testid="app-container"
        className={clsx("text-center h-[100vh] md:overflow-y-visible", {
          "overflow-y-hidden": isOpen,
        })}
      >
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
