import { useEffect, useState } from "react";
import queryString from "query-string";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import Root from "./root";
import AppContextProvider from "./themecontext";

interface RootProps {
  isOpen?: boolean;
  setOpen?: (_: boolean) => void;
  msalInstance: any;
  logOutUser?: () => Promise<void>;
}

export default function RootPage({
  isOpen,
  setOpen,
  msalInstance,
  logOutUser,
}: RootProps) {
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();
  const parsedParams = queryString.parse(search);

  const { inProgress } = useMsal();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [inLoginFlow, setInLoginFlow] = useState<boolean>(false);
  const [data, setData] = useState<string>("");

  useEffect(() => {
    setOpen?.(false);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const { code, loginHint, redirectAfterLogin } = parsedParams;
      if (code && !data) {
        setLoginLoading(true);
        if (inProgress === "none") {
          try {
            const token = await msalInstance.acquireTokenByCode({
              code,
            });
            setData(token.accessToken);
            navigate(`${redirectAfterLogin ?? "/"}`);
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              try {
                const token = await msalInstance.loginPopup({
                  loginHint,
                });
                setData(token.accessToken);
                navigate("/");
              } catch (error) {}
            }
          }
        } else if (inProgress === "startup" && code && !data) {
          setInLoginFlow(true);
        }
        setLoginLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [msalInstance, inProgress]);

  return (
    <AppContextProvider>
      <Root
        isOpen={isOpen}
        setOpen={setOpen}
        loginLoading={loginLoading}
        logOutUser={logOutUser}
        setInLoginFlow={setInLoginFlow}
        inLoginFlow={inLoginFlow}
      />
    </AppContextProvider>
  );
}
