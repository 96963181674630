/* @prettier */
import { forwardRef } from 'react';
import { useSearch } from '../../providers';
import { SidebarDesktopProps } from './Sidebar.types';
import { CollapsibleLayout, ListLayout } from './Sidebar.utils';

export default forwardRef<HTMLDivElement, SidebarDesktopProps>(function SidebarDesktop(
  { navItems, onClick, activePage },
  ref,
) {
  const { searchInput } = useSearch();
  return (
    <div className='text-base pb-2' style={{ overflowY: 'auto' }}>
      {navItems
        .filter(x => x.to === activePage.to)
        .flatMap(x => x.subMenu ?? [])
        .map(({ label, to = '', children = null }, index) => {
          const showLabel =
            label === 'Overview' || label?.toLowerCase().includes(searchInput.toLowerCase());

          if (children !== null) {
            return (
              <CollapsibleLayout
                defaultExpanded
                key={index}
                label={label}
                onClick={to => to && onClick(to)}
                children={children}
                ref={ref}
              />
            );
          } else {
            return showLabel ? (
              <ListLayout key={index} label={label} onClick={to => to && onClick(to)} to={to} />
            ) : null;
          }
        })}
    </div>
  );
});
