import React from "react";
import GenericCard from "./genericCard/GenericCard";

export default function FoundationsCard(props: { currentValue?: string }) {
  return (
      <GenericCard
        currentValue={props.currentValue}
        bgImage={"/componentAssets/foundation.svg"}
        navigateTo={"/foundations"}
        linkTitle={"See More"}
        title={" Foundations"}
        description={`Foundations encompass a comprehensive set of design elements
            essential for creating UI components, surfaces, and patterns,
            ranging from fundamental graphic elements such as iconography,
            color, and typography to structural elements like elevation, layout,
            and accessibility considerations. They serve as the bedrock upon
            which cohesive and user-friendly designs are built.`}
      />
  );
}
