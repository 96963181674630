import { GuidelinesProps } from "./Guidelines.types";
import Usage from "../../../../component/usage";
import Placement from "../../../../component/placement/Placement";

export default function Guidelines({ data }: GuidelinesProps) {
  return (
    <main className="mt-12 mb-20 lg:max-w-[840px]">
      {data.sections.map((section: any) => {
        if (section.id === "placement" && section.visible) {
          return <Placement key={section.title} placementSection={section} CustomPlacement={section.customPlacement} />;
        } else if (section.id === "usage" && section.visible) {
          return (
            <Usage
              key={section.title}
              optionalCopy={section.optionalCopy}
              title={section.title}
              tiles={section.tiles}
            />
          );
        } else {
          return "";
        }
      })}
    </main>
  );
}
