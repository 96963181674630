import React from "react";
import { PlacementProps } from "./Placement.types";

function Placement({ placementSection, CustomPlacement }: PlacementProps) {
  return CustomPlacement ? (
    <>{CustomPlacement()}</>
  ) : (
    <section
      className={`text-left mt-12 ${placementSection.className}`}
      data-testid="placement-section"
    >
      <h3 className="text-[28px] leading-9 font-sans  mb-6 max-w-[600px]">
        {placementSection.title}
      </h3>
      {placementSection.subSection.map(
        ({ title, requiredCopy, images, customContent }, index) => {
          const indexKey = index;
          return (
            <section key={indexKey} className="mb-14">
              <h5 className="text-xl font-extrabold font-sans  mb-1 max-w-[600px]">
                {title}
              </h5>
              <p className="leading-6  mb-10 max-w-[600px]">
                {requiredCopy}
              </p>
              <aside className="flex flex-col sm:flex-row gap-4">
                {customContent ??
                  images.map((src, index) => {
                    const indexKey = index;
                    return (
                      <img
                        key={indexKey}
                        className={`w-full ${
                          images.length > 1 ? "sm:w-1/2" : "sm:w-full"
                        }`}
                        src={src}
                        alt={`${index + 1}`}
                      />
                    );
                  })}
              </aside>
            </section>
          );
        }
      )}
    </section>
  );
}

export default Placement;
