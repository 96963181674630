/** @prettier */

import clsx from 'clsx';
import { forwardRef, ReactElement, ReactNode, useEffect, useReducer, useState } from 'react';
import { css } from '../../css';
import { useNavigation } from '../../hooks/useNavigation';
import { useSearch } from '../../providers';

function openItemsReducer(currentState: number[], toggleIndex: number) {
  const nextState = [...currentState];
  const ix = nextState.indexOf(toggleIndex);
  if (~ix) {
    nextState.splice(ix, 1);
  } else {
    nextState.push(toggleIndex);
  }
  return nextState;
}

export function useItemOpenState() {
  const [state, dispatch] = useReducer(openItemsReducer, []);
  return [
    function isOpen(itemIndex: (typeof state)[number]) {
      return !!~state.indexOf(itemIndex);
    },
    dispatch,
  ] as const;
}

const defaultStyle = 'h-[40px] relative rounded-lg text-inherit cursor-pointer';
const focusStyle =
  'focus-visible:shadow-[0px_0px_0px_4px_#C8DAEB,inset_0px_0px_1px_2px_#1B6EBB] focus-visible:text-[#1B6EBB] focus-visible:bg-white';
const activeStyle =
  'active:bg-[#BCDFFD] active:shadow-[inset_0px_0px_1px_2px_#033B69] focus-visible:text-[#033B69]';
const selectedStyle = 'bg-[#FFFFFF] shadow-[inset_0px_0px_1px_1px_#C8DAEB]';

export const ListLayout = ({
  open,
  level = 1,
  label,
  isDark,
  onClick,
  to,
}: {
  open?: boolean;
  level?: 0 | 1;
  label: ReactNode;
  isDark?: boolean;
  onClick: (to?: string) => void;
  to?: string;
}) => {
  const parent = typeof open === 'boolean';

  const { getSlug, getLocation } = useNavigation();
  const topLevel = getLocation.pathname.split('/')[1];

  return label === undefined ? (
    <></>
  ) : (
    <div className={clsx('grid px-2', isDark ? 'text-[#fff]' : '')}>
      <button
        className={clsx(
          'py-2 cursor-pointer text-left outline-none block',
          defaultStyle,
          focusStyle,
          activeStyle,
          getLocation.pathname === to || `/${topLevel}/${getSlug}` === to
            ? selectedStyle
            : undefined,
          level ? 'pl-8 pr-4' : 'px-4',
        )}
        style={css({
          on: $ => [
            $('&:hover', {
              background: '#ebf3fa',
              color: '#124c81',
              boxShadow: 'inset 0px 0px 1px 2px #124C81',
            }),
          ],
        })}
        onClick={() => onClick(to)}
      >
        <div className='flex items-center justify-between gap-4'>
          <span className='line-clamp-1'>{label}</span>
          {parent ? (
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              style={{ transform: open ? 'scaleY(-1)' : undefined }}
            >
              <polyline points='0 0 6 6 12 0' />
            </svg>
          ) : undefined}
        </div>
      </button>
    </div>
  );
};

export const CollapsibleLayout = forwardRef<
  HTMLDivElement,
  {
    defaultExpanded?: boolean;
    label: ReactNode;
    isDark?: boolean;
    onClick: (to?: string) => void;
    children: ReactElement[];
  }
>(({ defaultExpanded = false, label, isDark, onClick, children }, ref) => {
  const { searchInput } = useSearch();
  const [itemOpen, setItemOpen] = useState(defaultExpanded);

  useEffect(() => {
    if (searchInput) {
      setItemOpen(true);
    } else if (!defaultExpanded) {
      setItemOpen(false);
    }
  }, [searchInput, defaultExpanded]);

  return (
    <div data-testid='collapsibleLayout'>
      <ListLayout
        open={itemOpen}
        level={0}
        isDark={isDark}
        onClick={() => setItemOpen(prev => !prev)}
        label={
          <span
            className={clsx('font-bold text-sm uppercase tracking-[1px]', {
              'text-[#1b6ebb]': !isDark,
            })}
          >
            {label}
          </span>
        }
      />
      {itemOpen ? (
        <div>
          {children
            .filter((child: any) => child.label.toLowerCase().includes(searchInput.toLowerCase()))
            .map((child: any, i: number) => {
              return (
                <ListLayout
                  key={child.to}
                  label={child.label}
                  to={child.to}
                  isDark={isDark}
                  onClick={onClick}
                />
              );
            })}
        </div>
      ) : null}
    </div>
  );
});
