import React from "react";
import { AnatomyProps } from "./Anatomy.types";
import Image from "@albertsons/uds/molecule/Image";
import clsx from "clsx";
import { CheckCircle2, MinusCircle } from "lucide-react";

export default function Anatomy(props: AnatomyProps) {
  return (
    <div className={clsx("overflow-y-auto", props.className)}>
      <section className="w-full" data-testid="anatomy-container">
        <div data-testid="anatomy-copy" className="flex justify-start mb-6">
          <h3 className=" text-[28px] leading-9  font-normal ">
            {props.title}
          </h3>
        </div>
        {typeof props.image?.src === "string" ? (
          <div
            data-testid="anatomy-image"
            className="mb-6 p-8 bg-[#F7F8FA] flex justify-center items-center"
          >
            <Image
              src={props.image?.src}
              alt={props.image?.alt}
              className={clsx("border-none", props.image?.className)}
              width={props.image.width}
            />
          </div>
        ) : (
          props.image?.src
        )}
        <div className="" data-testid="anatomy-table">
          <table className="w-full text-left">
            <tbody>
              <tr className="mb-4 border-b border-b-[#C8DAEB]">
                <th className="p-4  text-base font-bold">Item</th>
                <th className="p-4  text-base font-bold">Name</th>
                <th className="p-4  text-base font-bold">
                  Description
                </th>
                <th className="p-4  w-[12%] text-base font-bold">
                  Required
                </th>
              </tr>
              {props?.descriptionCopy?.map((item, index) => {
                const indexKey = index;
                return (
                  <tr
                    key={indexKey}
                    className={clsx("p-4 border-b border-b-[#C8DAEB]")}
                  >
                    <td className=" p-4 text-base leading-5 ">
                      <p
                        className={clsx(
                          "h-6 w-6 rounded-full bg-[#0D2D49] text-white flex justify-center items-center"
                        )}
                      >
                        {index + 1}
                      </p>
                    </td>
                    <td className=" p-4 text-base leading-5 ">
                      {item.name}
                    </td>
                    <td className="p-4 text-base leading-5    break-normal inline-block">
                      {item.description}
                    </td>
                    <td className=" text-base leading-5 p-4">
                      {item.required ? (
                        <CheckCircle2
                          color="#2D7207"
                          aria-label="checkCircle"
                        />
                      ) : (
                        <MinusCircle color="#5A697B" aria-label="minusCircle" />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}
