import { SpecsProps } from "./Specs.types";
import Anatomy from "../../../../component/anatomy";
import Measurements from "../../../../component/Measurements";
import CodeComponent from "../../../../component/codeComponent/CodeComponent";

export default function Specs({ data }: SpecsProps) {
  return (
    <main className="mt-12 mb-20 lg:max-w-[848px]">
      {data.sections.map((section: any) => {
        if (section.id === "anatomy" && section.visible) {
          return (
            <Anatomy
              key={section.title}
              title={section.title}
              descriptionCopy={section.items}
              image={section.image}
              className={section.className}
            />
          );
        } else if (section.id === "liveSpec" && section.visible) {
          return (
            <div key={section.title} className="mt-20">
              <CodeComponent
                key={section.title}
                title={section.title}
                optionalCopy={section.optionalCopy}
                editors={section.items}
              />
            </div>
          );
        } else if (section.id === "measurements" && section.visible) {
          return (
            <div key={section.title} className="mt-20">
              <Measurements title="Measurements" data={section.images} />
            </div>
          );
        } else {
          return "";
        }
      })}
    </main>
  );
}
