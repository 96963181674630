import React from "react";
import GenericCard from "./genericCard/GenericCard";

export default function TokenssCard(props: { currentValue?: string }) {
  return (
    <GenericCard
      currentValue={props.currentValue}
      bgImage={"/componentAssets/token.svg"}
      navigateTo="foundations/design-tokens"
      linkTitle={"See More"}
      title={" Design Tokens"}
      description={`Design tokens represent design values as data. Tokens enable
            designers and developers to use consistent styles and support
            theming of our library.`}
    />
  );
}
