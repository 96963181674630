import { useEffect, useRef, useState } from "react";
import { PropsTableProps } from "./PropsTable.types";
import clsx from "clsx";

export default function PropsTable(props: PropsTableProps) {
  const refer = useRef<HTMLIFrameElement>(null);
  const baseUrl = "/meui-doc/";

  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState("");
  const [frameClass, setFrameClass] = useState("invisible");

  const getIframe = () => {
    const iframeDoc =
      refer?.current?.contentDocument ||
      refer?.current?.contentWindow?.document;

    return iframeDoc;
  };

  const configureIframe = () => {
    const iframeDocument = getIframe();
    const styleElement = document.createElement("style");
    styleElement.textContent = ".css-zgt7u1  { padding: 0px !important; }";
    iframeDocument?.head.appendChild(styleElement);
    const heading = iframeDocument?.getElementsByTagName("h1") || [];
    for (let i = 0; i < heading.length; i++) {
      heading[i].style.display = "none";
    }
    const allElements = iframeDocument?.getElementsByTagName("section") || [];
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].style.display = "none";
      if (allElements[i].id === "props-table") {
        allElements[i].style.display = "block";
      }
    }
    const frameH = iframeDocument?.body.scrollHeight + "px";
    setHeight(frameH);
    setLoaded(!loaded);
    setFrameClass("block overflow-hidden");
  };
  const onLoad = () => {
    configureIframe();
  };

  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    setLoaded(false);
    function handleResize() {
      if (window.innerWidth !== windowWidth.current) {
        window.location.reload();
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className=" min-h-[30vh]">
      <div className="flex flex-col items-start">
        <h3 className=" text-[28px] leading-9  font-normal ">
          {props.title}
        </h3>
        <p className=" text-base  font-normal ">
          {props?.optionalCopy}
        </p>
      </div>
      <div className={clsx(!loaded ? "min-h-screen" : "h-full")}>
        {!loaded && (
          <div className=" flex justify-center items-center h-screen">
            <div className=" animate-spin rounded-full border-t-4 border-[#00529F] h-20 w-20"></div>
          </div>
        )}
        {
          <iframe
            onLoad={onLoad}
            title="Canvas"
            id="storybook-docs-frame"
            className={frameClass}
            width={"100%"}
            ref={refer}
            src={baseUrl + props.url}
            height={height}
          ></iframe>
        }
      </div>
    </section>
  );
}
