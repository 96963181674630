import About from "../../../../component/about";
import Example from "../../../../component/example";
import Related from "../../../../component/related";
import { OverviewProps } from "./Overview.types";

export default function Overview({ data }: OverviewProps) {
  return (
    <main className="mt-12 mb-20 lg:max-w-[840px]">
      {data.sections.map((section: any) => {
        if (section.id === "about" && section.visible) {
          return (
            <About
              key={section.title}
              title={section.title}
              Content={section.content}
            />
          );
        } else if (section.id === "examples" && section.visible) {
          return (
            <div key={section.title} className="mt-[88px]">
              <Example
                title={section.title}
                copy={section.items}
                image={section.image}
                optionalCopy={section?.copy}
              />
            </div>
          );
        } else if (section.id === "related" && section.visible) {
          return (
            <div key={section.title} className="mt-[88px]">
              <Related title={section.title} components={section.components} />
            </div>
          );
        } else {
          return "";
        }
      })}
    </main>
  );
}
