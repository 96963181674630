import clsx from "clsx";
import { GenericHeroProps } from "./GenericHero.types";

export default function GenericHero({
  name = "",
  description = "",
  image,
}: GenericHeroProps) {
  return (
    <div className="flex flex-col sm:flex-row rounded-[20px] overflow-hidden">
      <div className="flex sm:w-1/2 min-h-[256px] items-center sm:mr-8">
        <div className="flex flex-col text-left sm:mr-20">
          <div
            className="text-[32px] text-[#1B6EBB] mb-6 leading-[38.4px] font-light"
            data-testid="GenericHeroName"
          >
            {name}
          </div>
          <div
            className="text-base  max-w-[600px]"
            data-testid="GenericHeroDescription"
          >
            {description}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "flex items-center h-64 bg-[#F1F4F9] rounded-[20px] sm:w-1/2 mt-6 sm:mt-0 overflow-hidden",
          image?.position === "left"
            ? "justify-start"
            : (() => {
                return image?.position === "right"
                  ? "justify-end"
                  : "justify-center";
              })()
        )}
      >
        <img
          data-testid="GenericHeroImage"
          style={{ width: image?.imageWidth }}
          className={image?.className}
          alt={image?.alt}
          src={image?.src}
        />
      </div>
    </div>
  );
}
