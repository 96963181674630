/** @prettier */
import { forwardRef } from 'react';
import { useSearch } from '../../providers';
import { SidebarMobileProps } from './Sidebar.types';
import { CollapsibleLayout, ListLayout, useItemOpenState } from './Sidebar.utils';

const SidebarMobile = ({ navItems, onClick, activePage, isDark }: SidebarMobileProps, ref: any) => {
  const { searchInput } = useSearch();
  const [itemOpen, toggleItemOpen] = useItemOpenState();

  return (
    <div className='text-base pb-8'>
      {navItems.map((item, ind) => {
        return (
          <div key={ind}>
            <div
              style={
                activePage?.label === item.label
                  ? { boxShadow: 'inset 4px 0 0 0 #3996EF' }
                  : undefined
              }
            >
              <ListLayout
                key={item.label}
                open={Boolean(searchInput) || itemOpen(ind)}
                level={0}
                label={<span className='font-bold'>{item.label}</span>}
                isDark={isDark}
                onClick={() => toggleItemOpen(ind)}
              />
            </div>
            {Boolean(searchInput) || itemOpen(ind) ? (
              <div>
                {item.subMenu?.map(({ label, to = '', children = null }, index) => {
                  if (children !== null) {
                    return (
                      <CollapsibleLayout
                        key={index}
                        label={label}
                        isDark={isDark}
                        onClick={to => to && onClick(to)}
                        children={children}
                        ref={ref}
                      />
                    );
                  } else {
                    return label?.toLowerCase().includes(searchInput.toLowerCase()) ? (
                      <ListLayout
                        key={index}
                        label={label}
                        isDark={isDark}
                        onClick={to => to && onClick(to)}
                        to={to}
                      />
                    ) : null;
                  }
                })}
              </div>
            ) : undefined}
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef(SidebarMobile);
