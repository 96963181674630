import React from "react";
import { UsageProps } from "./UsageComponent.types";
import clsx from "clsx";
import UsageTile from "./UsageTile";

export default function UsageComponent(props: UsageProps) {
  return (
    <section className="" data-testid="usage-container">
      {(props.title || props.optionalCopy) && (
        <div
          data-testid="usage-copy"
          className="flex flex-col items-start mb-6"
        >
          <h3
            className=" text-[28px] leading-9  font-normal "
            data-testid="UsageComponentTitle"
          >
            {props.title}
          </h3>
          <p
            className=" text-base  font-normal "
            data-testid="UsageComponentOptionalCopy"
          >
            {props?.optionalCopy}
          </p>
        </div>
      )}
      <div
        data-testid="usage-tiles"
        className=" grid gap-4  gap-y-8 auto-rows-min grid-cols-1 sm:grid-cols-2"
      >
        {props.tiles?.map((tile, index) => {
          const indexKey = index;
          return (
            <div
              className={clsx({ "col-span-full": tile.variant === "full" })}
              key={indexKey}
            >
              <UsageTile {...tile} />
            </div>
          );
        })}
      </div>
    </section>
  );
}
