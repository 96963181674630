/** @prettier */

import { Children, FC, ReactNode } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { css } from '../../css';
import * as V from '../../variables';

const year = new Date().getUTCFullYear();

export const FooterBottomRow: FC<{ links?: ReactNode }> = function FooterBottomRow({ links }) {
  return (
    <div
      style={css({
        color: V.secondary201DarkText,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: V.secondary204Gray,
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '0.375rem 0.75rem',
        fontFamily: V.sansSerif,
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        on: $ => [
          $('@media (prefers-color-scheme: dark)', {
            color: V.primary106White,
            borderTopColor: V.secondary202LightText,
          }),
        ],
      })}
    >
      <div style={{ flexGrow: 1, textAlign: 'center' }}>&copy; {year} Albertsons</div>
      <div style={{ flexBasis: 'calc((40rem - 100%) * 999)', flexGrow: 999 }} />
      {Children.map(flattenChildren(links), (link, i) => (
        <>
          {i ? <div style={{ flexBasis: 'calc((40rem - 100%) * 999)', flexGrow: 1 }} /> : undefined}
          {link}
        </>
      ))}
    </div>
  );
};
