import React from "react";
import { ExampleProps } from "./Example.types";
import Image from "@albertsons/uds/molecule/Image";
import Bullet from "./Bullet";
import clsx from "clsx";

export default function Example(props: ExampleProps) {
  return (
    <section className="w-full" data-testid="example-container">
      <div
        data-testid="example-copy"
        className="flex flex-col items-start mb-6"
      >
        <h3
          className=" text-[28px] leading-9  font-normal "
          data-testid="ExampleTitle"
        >
          {props.title}
        </h3>
        <p className=" text-base  font-normal ">
          {props?.optionalCopy}
        </p>
      </div>
      {typeof props.image?.src === "string" ? (
        <div
          data-testid="anatomy-image"
          className="mb-6 p-8 bg-[#F7F8FA] flex justify-center items-center"
        >
          <Image
            src={props.image?.src}
            alt={props.image?.alt}
            className={clsx("border-none", props.image?.className)}
            width={props.image?.width}
          />
        </div>
      ) : (
        props.image?.src
      )}
      <div data-testid="example-details" className=" text-left">
        {props?.copy?.map((item, index) => {
          const indexKey = index;
          return (
            <Bullet
              key={indexKey}
              number={index + 1}
              label={item.description}
              className={clsx(index === 0 ? "mb-4 mt-0" : "my-4")}
            />
          );
        })}
      </div>
    </section>
  );
}
