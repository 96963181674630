import Navbar from "./NavBar";
import Grid from "./Grid";
import { useNavigation } from "../../hooks/useNavigation";
import { css } from "../../css";
import UDSLogo from "../../icons/UDSLogo";
import * as V from "../../variables";

interface HeaderProps {
  currentPage: string;
  isOpen?: boolean;
  setOpen?: (_: boolean) => void;
}

const Header = ({
  isOpen = false,
  setOpen = () => {},
  currentPage,
}: HeaderProps) => {
  const { navigateToURL } = useNavigation();

  return (
    <header
      data-testid='header'
      className={
        "h-[80px] w-full flex bg-transparent flex-row justify-between z-50 items-center px-5 py-5 fixed overflow-hidden z-[202]"
      }
      style={ css({
        background: "#FFFFFF",
        on: $ => [$("@media (prefers-color-scheme: dark)", { background: V.darkText })]
      })}
    >
      <div className="flex items-center">
        <button
          data-testid='link-container'
          className="flex items-center"
          onClick={() => {
            navigateToURL("/");
          }}
        >
          <a
            href='/'
            style={css({
              flexShrink: 0,
              cursor: 'pointer',
              lineHeight: '2.875rem',
              on: ($, { not, or }) => [
                $(not(or('@screen (mobile)', '@screen (tablet b)')), {
                  lineHeight: '3.75rem',
                }),
              ],
            })}
            onClick={e => {
              if (navigateToURL) {
                e.preventDefault();
                const href = e.currentTarget.getAttribute('href');
                if (href) {
                  navigateToURL(href);
                }
              }
            }}
          >
            <UDSLogo width={undefined} height='1lh' />
          </a>
        </button>
      </div>

      <nav>
        <ul className="md:hidden flex justify-end">
          <li
            style={css({
              color: V.darkText,
              on: $ => [
                $("@media (prefers-color-scheme: dark)", { color: "#FFFFFF" })
              ]
            })}
          >
            <div
              data-testid='grid-container'
              role="button"
            >
              <Grid isOpen={isOpen} setOpen={setOpen} />
            </div>
          </li>
        </ul>
        <Navbar currentPage={currentPage} />
      </nav>
    </header>
  );
};

export default Header;
