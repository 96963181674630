import React from "react";
import GenericHero from "../../../component/hero/genericHero/GenericHero";
import Tabs from "../../../component/tabs";
import { COMPONENT_DETAILS_TABS } from "../../utils";
import Tab from "../../../component/tabs/tab/Tab";
import Overview from "../../../routes/componentPage/component/overview";
import Specs from "../../../routes/componentPage/component/specs";
import Guidelines from "../../../routes/componentPage/component/guidelines";
import Playground from "../../../routes/componentPage/component/playground";
import Props from "../../../routes/componentPage/component/props";
import { CDPProps } from "./ComponentDetail.types";

export default function ComponentDetail(props: CDPProps) {
  return (
    <div className="w-full">
      <GenericHero
        name={props.title}
        description={props.subtitle}
        image={{
          src: props.src,
          imageWidth: props.imageWidth,
          alt: props.imageAlt,
          className: props.className,
        }}
      />
      <div className="flex mt-8">
        <Tabs activeTab={props.activeTab}>
          {!props.developed
            ? Object.entries(COMPONENT_DETAILS_TABS)
                .slice(0, 3)
                .map(([key, value], index) => {
                  return (
                    <Tab
                      key={key}
                      id={index}
                      url={value.url}
                      text={value.name}
                      onClick={() => {
                        props.navigateToURL(null, value.url);
                      }}
                    />
                  );
                })
            : Object.entries(COMPONENT_DETAILS_TABS).map(
                ([key, value], index) => {
                  return (
                    <Tab
                      key={key}
                      id={index}
                      url={value.url}
                      text={value.name}
                      onClick={() => {
                        props.navigateToURL(null, value.url);
                      }}
                    />
                  );
                }
              )}
        </Tabs>
      </div>
      {props.activeTab === COMPONENT_DETAILS_TABS.overview.url && (
        <Overview data={props.overviewData} />
      )}
      {props.activeTab === COMPONENT_DETAILS_TABS.specs.url && (
        <Specs data={props.specsData} />
      )}
      {props.activeTab === COMPONENT_DETAILS_TABS.guidelines.url && (
        <Guidelines data={props.guidelinesData} />
      )}
      {props.developed && (
        <>
          {props.activeTab === COMPONENT_DETAILS_TABS.playground.url && (
            props.playgroundData &&  <Playground data={ props.playgroundData} />
          )}
          {props.activeTab === COMPONENT_DETAILS_TABS.props.url && (
          props.propsData &&  <Props data={props.propsData} />
          )}
        </>
      )}
    </div>
  );
}
