import { PlaygroundProps } from "./Playground.types";
import PlayGround from "../../../../component/playground";

export default function Playground({ data }: PlaygroundProps) {
  return (
    <main className="mt-12 mb-20 lg:max-w-[840px]">
      <PlayGround title={data.title} url={data.url} />
    </main>
  );
}
