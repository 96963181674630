/* @prettier */

import { ComponentProps, FC, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

const ArrowRight: FC = function ArrowRight() {
  return (
    <svg
      style={css({
        fontSize: V.bigCornerRadius, // TODO: Should this be a variable?
        width: '1em',
        height: '1em',
        minWidth: '1em',
        minHeight: '1em',
        on: $ => [
          $('@container (width >= 32rem)', {
            fontSize: '2rem',
          }),
        ],
      })}
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M5.33334 15.9999H26.6667M26.6667 15.9999L16 5.33325M26.6667 15.9999L16 26.6666'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const HeadingRow: FC<{
  children?: ReactNode;
}> = function HeadingRow({ children }) {
  return (
    <div
      style={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.75rem',
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
        on: $ => [
          $('@container (width >= 32rem)', {
            fontSize: '1.75rem',
          }),
        ],
      })}
    >
      <span>{children}</span>
      <ArrowRight />
    </div>
  );
};

export const HomeCardLink: FC<
  Pick<ComponentProps<'a'>, 'href' | 'target' | 'onClick'> & {
    disabled?: boolean;
    heading: ReactNode;
    description?: ReactNode;
  }
> = function HomeCardLink({ disabled, href, target, onClick = () => {}, heading, description }) {
  return (
    <a
      className='group'
      href={href}
      target={target}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
        } else {
          onClick(e);
        }
      }}
      style={css({
        fontFamily: V.sansSerif,
        fontWeight: 600,
        textDecoration: 'none',
        outlineWidth: 0,
        outlineOffset: '0.125rem',
        outlineStyle: 'solid',
        outlineColor: V.secondary204Gray,
        cursor: 'pointer',
        display: 'inline-flex',
        minWidth: '12.5rem',
        maxWidth: '17.5rem',
        padding: '0.75rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.20)',
        borderRadius: V.smallCornerRadius,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: V.secondary205Gray,
        background: '#fff',
        color: V.primary101Brand,
        on: ($, { and, not, or }) => [
          $('&:disabled', {
            cursor: 'not-allowed',
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:hover'), {
            background: V.tertiary306Blue,
            color: V.primary104Brand,
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:active'), {
            background: V.tertiary305Blue,
            color: V.tertiary301Blue,
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:focus-visible'), {
            outlineWidth: '0.25rem',
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:disabled'), {
            color: V.functional404DisabledObject,
          }),
          $('@media (prefers-color-scheme: dark)', {
            borderColor: V.extended513NeutralGray900,
            background: V.extended513NeutralGray900,
            color: V.tertiary305Blue,
          }),
          $(and('@media (prefers-color-scheme: dark)', '&:intent'), {
            borderColor: V.tertiary304Blue,
            color: V.tertiary304Blue,
          }),
          $('@container (width >= 32rem)', {
            gap: V.smallCornerRadius, // TODO: Should this be a variable?
            padding: '1rem',
          }),
        ],
      })}
    >
      <HeadingRow>{heading}</HeadingRow>
      {description && (
        <div
          style={css({
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: 'inherit',
            on: ($, { or, not }) => [
              $(not(or('@media (prefers-color-scheme: dark)', '.group:disabled &')), {
                color: V.secondary202LightText,
              }),
              $('@media (prefers-color-scheme: dark)', {
                color: V.primary106White,
              }),
            ],
          })}
        >
          {description}
        </div>
      )}
    </a>
  );
};
