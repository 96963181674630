import { DetailBanner } from "../../components/detail-banner";
import illustration from "./illustration.svg";

export default function TermsPage() {
  return (
    <section style={{ textAlign: "left", padding: "0 2rem" }} data-testid="legal-container">
      <DetailBanner
        heading={<h1 style={{ all: "unset" }}>Terms of Use</h1>}
        description={<p style={{ all:"unset" }}>Understand the rules governing your use of the Unified Design System</p>}
        graphic={<img src={illustration} alt="Person reading an agreement" />} />
      {/* <div className="mb-8">
        <img
          src="/images/terms_placeholder.png"
          alt="placeholder"
          className="w-full h-[208px]"
        />
      </div> */}

      <div className="max-w-[600px] mt-[70px]">
        <h3 className="font-normal text-[28px] leading-[36px] pb-4">
          Merchant and Supplier Review Portal Terms and Conditions
        </h3>
        <p className="text-base font-normal pb-4">
          By creating an account in our systems, you hereby attest that you are
          either: (a) a Seller; or (b) acting as an agent on behalf of a Seller
          as each of the terms "Seller" and "agent" is used in Continuing
          Commodity Guaranty and Indemnification Agreement Terms and Conditions
          (CCG) and in each case subject to the obligations of the Seller in
          question under the terms of the CCG.
        </p>
        <p className="text-base font-normal pb-4">
          When you or your employer create an account for you to use our
          systems, we may collect your name, email address, phone number, job
          title, job location, and login information to enable the functionality
          of the system. You may also be able to add personal data, like a photo
          or additional contact information, or to set preferences related to
          report generation, business notifications and, in limited
          circumstances, marketing. Albertsons Companies may share this personal
          data with service providers who assist us with providing the software
          and tools necessary for the functionality of our systems. These
          service providers are contractually required to protect your personal
          data and are not allowed to use it for purposes beyond those specified
          in our contracts.
        </p>
        <p className="text-base font-normal">
          We keep the personal data collected, used and stored by these systems
          to a minimum and the personal data is not connected with any profile
          or account information you may have as a customer of Albertsons
          Companies stores. If you have any questions about Albertsons Companies
          processing of data collected in the context of your work with a vendor
          or client of ours, and the need to access systems associated with that
          work, please contact privacyoffice@albertsons.com.
        </p>
      </div>
    </section>
  );
}
