import clsx from "clsx";
import React from "react";
interface Props {
  number: number;
  label: string;
  className?: string;
  bulletClassName?: string
}

export default function Bullet(props: Props) {
  return (
    <div className={clsx("flex", props.className)}>
<div className="min-w-7">  <p className={clsx("h-6 w-6 rounded-full bg-[#0D2D49] text-white mr-2 flex justify-center items-center", props.bulletClassName)}>
        {props.number}
      </p></div>
      <p className="text-base  max-w-[600px]">{props.label}</p>
    
    </div>
  );
}
