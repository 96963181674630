import React from "react";
import { navLinks } from "./utils/navligationLinkLists";
import { useNavigation } from "../../hooks/useNavigation";
import { css } from "../../css";
import * as V from "../../variables";

interface NavBarProps {
  currentPage: string;
}

const Navbar: React.FC<NavBarProps> = ({ currentPage }) => {
  const { navigateToURL } = useNavigation();
  return (
    <div className="hidden md:flex items-center h-16 justify-end ">
      {navLinks.map(
        ({ label, to, subMenu }: { label: string; to: string, subMenu?: { to?: string }[] }, index: number) => {
          const isSelected =
            currentPage?.toLowerCase() === label.toLowerCase() ||
            subMenu?.some(x => x.to === `/${currentPage?.toLowerCase()}`);
          return (
            <div
              key={to}
              style={css({
                margin: '0 12px',
                height: '54px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                lineHeight: '24px',
                color: V.darkText,
                borderBottomStyle: 'solid',
                marginTop: '4px',
                borderBottomWidth: '4px',
                on: ($, { and, not }) => [
                  $(not('@media (prefers-color-scheme: dark)'), {
                    borderBottomColor: isSelected ? V.brand : 'transparent',
                  }),
                  $('@media (prefers-color-scheme: dark)', {
                    color: 'white',
                    borderBottomColor: isSelected ? 'white' : 'transparent',
                  }),
                  $('&:hover', { borderBottomColor: V.brand }),
                  $(and('&:hover', '@media (prefers-color-scheme: dark)'), {
                    borderBottomColor: V.blue303,
                  }),
                ],
              })}
              onClick={() => {
                navigateToURL(to);
              }}
            >
              {label}
            </div>
          );
        }
      )}
    </div>
  );
};

export default Navbar;
