/** @prettier */

import { FC, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

const DetailBannerLeft: FC<{ heading: ReactNode; description: ReactNode }> =
  function DetailBannerLeft({ heading, description }) {
    return (
      <div
        style={css({
          fontFamily: V.sansSerif,
          padding: '3rem 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          on: $ => [
            $('@container (width >= 44rem)', {
              padding: '5rem 0',
            }),
          ],
        })}
      >
        <div style={{ fontSize: '2.5rem', lineHeight: '3rem', color: V.primary101Brand }}>
          {heading}
        </div>
        <div style={{ fontSize: '1rem', lineHeight: '1.5rem', color: V.secondary201DarkText }}>
          {description}
        </div>
      </div>
    );
  };

const DetailBannerRight: FC<{ children: ReactNode }> = function DetailBannerRight({ children }) {
  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        height: '16rem',
        padding: '1.5rem 0',
        background: V.secondary206Gray,
        borderRadius: V.bigCornerRadius,
      }}
    >
      {children}
    </div>
  );
};

export const DetailBanner: FC<{
  heading: ReactNode;
  description: ReactNode;
  graphic: ReactNode;
}> = function DetailBanner({ heading, description, graphic }) {
  return (
    <div style={{ containerType: 'inline-size' }}>
      <div
        style={css({
          display: 'grid',
          gridTemplateRows: 'auto auto',
          gridTemplateColumns: 'auto',
          gap: '1.5rem',
          on: $ => [
            $('@container (width >= 44rem)', {
              gridTemplateRows: 'auto',
              gridTemplateColumns: '1fr 1fr',
              flexDirection: 'row',
            }),
          ],
        })}
      >
        <DetailBannerLeft heading={heading} description={description} />
        <DetailBannerRight>{graphic}</DetailBannerRight>
      </div>
    </div>
  );
};
