/** @prettier */

import { TabBar } from '@albertsons/uds/molecule/TabBar';
import { Tab } from '@albertsons/uds/molecule/Tabs';
import { ComponentProps } from 'react';
import ComponentDetail from '../../components/componentDetail/ComponentDetail';
import anatomy from './anatomy.svg';
import example from './example.svg';
import illustration from './illustration.svg';
import measurements1 from './measurements1.svg';
import measurements2 from './measurements2.svg';
import measurements3 from './measurements3.svg';
import usage1 from './usage1.svg';
import usage2 from './usage2.svg';
import usage3 from './usage3.svg';
import usage4 from './usage4.svg';
import usage5 from './usage5.svg';
import usage6 from './usage6.svg';
import usage7 from './usage7.svg';
import usage8 from './usage8.svg';

export const name = 'Tab Bar';

export const description =
  'The Tab Bar component makes it easy to explore and switch between groups of content that are related and at the same level of hierarchy.';

export { illustration };

function About() {
  return (
    <div>
      <p>{description}</p>
    </div>
  );
}

const overviewData = {
  id: 'overview',
  title: 'Overview',
  sections: [
    {
      id: 'about',
      title: 'About',
      visible: true,
      content: About,
    },
    {
      id: 'examples',
      title: 'Examples',
      visible: true,
      image: {
        src: example,
        alt: 'Tab Bar example',
        className: '',
      },
      copy: '',
      items: [{ description: 'Tab Bar with border' }, { description: 'Tab Bar without border' }],
    },
    {
      id: 'related',
      title: 'Related components',
      visible: false,
      components: [
        {
          id: 'Tab',
        },
        {
          id: 'Button',
        },
      ],
    },
  ],
};

const specsData = {
  id: 'specs',
  title: 'Specs',
  sections: [
    {
      id: 'anatomy',
      title: 'Anatomy',
      visible: true,
      image: {
        src: anatomy,
        alt: 'Anatomy',
        className: '',
      },
      items: [
        {
          name: 'Left Scroll Button',
          required: true,
          description: 'Scrolls hidden tabs to right by one tab width.',
        },
        {
          name: 'Border',
          required: false,
          description: 'An optional border around component.',
        },
        {
          name: 'Tab Divider',
          required: true,
          description: 'Visual separator between tabs.',
        },
        {
          name: 'Tab Text',
          required: true,
          description: 'Describes the content within the tab.',
        },
        {
          name: 'Tab Icon',
          required: false,
          description: 'Optional icon to add more information to a tab.',
        },
        {
          name: 'Right Scroll Button',
          required: true,
          description: 'Scrolls hidden tabs to left by one tab width.',
        },
        {
          name: 'Active Tab Indicator',
          required: true,
          description: 'Indicates which tab is currently active.',
        },
        {
          name: 'Tabs Container',
          required: true,
          description:
            'Container for tabs where `width` is set via `className` prop and all overflow is `hidden`.',
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Variants',
      optionalCopy: 'Tab Bars can be used with or without borders.',
      visible: true,
      items: [
        {
          title: 'Default',
          optionalCopy:
            'When `hideBorder` is `false`, a gray border wraps the outside of the component to help distinguish it from the background.',
          scope: { TabBar, Tab },
          code: `
// import React from 'react';
// import { Tab } from '@albertsons/uds/molecule/Tabs';
// import { TabBar } from '@albertsons/uds/molecule/TabBar';

const App = () => {
  return (
    <TabBar className="w-full">
      <Tab>
        Tab #1
      </Tab>
      <Tab>
        Tab #2
      </Tab>
      <Tab>
        Tab #3
      </Tab>
      <Tab>
        Tab #4
      </Tab>
    </TabBar>
  );
};

render(<App/>);`,
          noInline: true,
        },
        {
          title: 'Without border',
          optionalCopy:
            'When `hideBorder` is `true`, there is no border containing the component. Use this variant when using Tab Bar within a white field to avoid too many nesting boxes.',
          scope: { TabBar, Tab },
          code: `
// import React from 'react';
// import { Tab } from '@albertsons/uds/molecule/Tabs';
// import { TabBar } from '@albertsons/uds/molecule/TabBar';

const App = () => {
  return (
    <TabBar className="w-full" hideBorder>
      <Tab>
        Tab #1
      </Tab>
      <Tab>
        Tab #2
      </Tab>
      <Tab>
        Tab #3
      </Tab>
      <Tab>
        Tab #4
      </Tab>
    </TabBar>
  );
};

render(<App/>);`,
          noInline: true,
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Selected',
      optionalCopy: 'When `selected` is true',
      visible: true,
      items: [
        {
          title: '',
          optionalCopy: 'When `selected` is true',
          scope: { TabBar, Tab },
          code: `
// import React from 'react';
// import { Tab } from '@albertsons/uds/molecule/Tabs';
// import { TabBar } from '@albertsons/uds/molecule/TabBar';

const App = () => {
  return (
    <TabBar className="w-full">
      <Tab selected>
        Selected
      </Tab>
      <Tab>
        Not selected
      </Tab>
      <Tab>
        Not selected
      </Tab>
    </TabBar>
  );
};

render(<App/>);`,
          noInline: true,
        },
      ],
    },
    {
      id: 'measurements',
      title: 'Measurements',
      visible: true,
      images: [
        {
          id: 1,
          image: measurements1,
          isBig: true,
          alt: 'tab bar measurements with multiple tabs',
        },
        {
          id: 2,
          image: measurements2,
          isBig: true,
          alt: 'tab bar measurements with single tab overview',
        },
        {
          id: 3,
          image: measurements3,
          isBig: true,
          alt: 'tab bar measurements with top and bottom padding',
        },
      ],
    },
  ],
};

const guidelinesData = {
  id: 'guidelines',
  title: 'Guidelines',
  sections: [
    {
      id: 'usage',
      title: 'Usage',
      visible: true,
      tiles: [
        {
          image: {
            src: usage1,
            className: '',
            alt: 'two tabs with one tab selected',
          },
          informationCopy: 'Display exactly one active tab at a time.',
          style: 'do',
          variant: 'half',
        },
        {
          image: {
            src: usage2,
            className: '',
            alt: 'two tabs both selected',
          },
          informationCopy: 'Display more than one active tab at a time.',
          style: 'dont',
          variant: 'half',
        },
        {
          image: {
            src: usage3,
            className: '',
            alt: 'two tabs labeled as "Product Line Monitor" and "Inventory"',
          },
          informationCopy:
            'Keep tab text as short as possible. The tabs have a max-width of 205px.',
          style: 'do',
          variant: 'half',
        },
        {
          image: {
            src: usage4,
            className: '',
            alt: 'two tabs, one with a short label and one with a long label that wraps to multiple lines of text',
          },
          informationCopy: "Don't let tab text get too long or wrap to multiple lines.",
          style: 'dont',
          variant: 'half',
        },
        {
          image: {
            src: usage5,
            className: '',
            alt: 'two tabs with the same text label "Unique View 1"',
          },
          informationCopy: 'Display duplicate tabs. Keep each tab uniquely and specifically named.',
          style: 'dont',
          variant: 'half',
        },
        {
          image: {
            src: usage6,
            className: '',
            alt: 'a single tab labeled "Lonely Tab"',
          },
          informationCopy: 'Use this component for less than two tabs.',
          style: 'dont',
          variant: 'half',
        },
        {
          image: {
            src: usage7,
            className: '',
            alt: 'two tabs labeled 1 and 2',
          },
          informationCopy:
            'Be careful not to use too many tabs. We recommend between 2 and 10 tabs.',
          style: 'caution',
          variant: 'half',
        },
        {
          image: {
            src: usage8,
            className: '',
            alt: 'two tabs labeled "Tab #1" and "Tab #2"',
          },
          informationCopy:
            'Avoid acronyms for tab labels unless they are widely known. Instead opt for simple descriptors.',
          style: 'caution',
          variant: 'half',
        },
      ],
    },
  ],
};

const playgroundData = {
  title: 'Component testing',
  url: '?path=/story/molecules-tabbar--tab-bar&shortcuts=false&singleStory=true',
};
const propsData = {
  title: 'Component properties',
  url: 'iframe.html?id=molecules-tabbar--tab-bar&viewMode=docs&shortcuts=false&singleStory=true',
};

const TabBarComponent = ({
  activeTab,
  navigateToURL,
}: Pick<ComponentProps<typeof ComponentDetail>, 'activeTab' | 'navigateToURL'>) => {
  return (
    <>
      <ComponentDetail
        title='Tab Bar'
        subtitle={description}
        src={illustration}
        imageWidth='213px'
        imageAlt='Tab Bar'
        developed
        activeTab={activeTab}
        navigateToURL={navigateToURL}
        overviewData={overviewData}
        specsData={specsData}
        guidelinesData={guidelinesData}
        playgroundData={playgroundData}
        propsData={propsData}
      />
    </>
  );
};
export default TabBarComponent;
