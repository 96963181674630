/* @prettier */

import { FC, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

export const HomeCardCtaLayout: FC<{ graphic?: ReactNode; link?: ReactNode }> =
  function HomeCardCtaLayout({ graphic, link }) {
    return (
      <div
        style={css({
          width: '100%',
          position: 'relative',
          containerName: 'group',
          containerType: 'inline-size',
        })}
      >
        {graphic}
        <div
          style={css({
            position: 'absolute',
            bottom: V.bigCornerRadius,
            right: `calc(-0.5 * ${V.bigCornerRadius})`,
            on: $ => [
              $('@container group (width < 44rem)', {
                bottom: `calc(-0.5 * ${V.bigCornerRadius})`,
                right: V.bigCornerRadius,
              }),
            ],
          })}
        >
          {link}
        </div>
      </div>
    );
  };
