import React from "react";
import Image from "@albertsons/uds/molecule/Image";
import clsx from "clsx";
import { CheckCircle2, AlertCircle, AlertTriangle } from "lucide-react";
import { Tile } from "./UsageComponent.types";

export default function UsageTile(props: Tile) {
  const getBG = () => {
    switch (props.style) {
      case "do":
        return "bg-[#2D7207]";
      case "dont":
        return "bg-[#BF2912]";
      case "caution":
        return "bg-[#9B3E08]";
      default:
        return "bg-[#2D7207]";
    }
  };
  const getIcon = () => {
    switch (props.style) {
      case "do":
        return <CheckCircle2 color="#2D7207" height="16px" width="16px" />;
      case "dont":
        return <AlertCircle color="#BF2912" height="16px" width="16px" />;
      case "caution":
        return <AlertTriangle color="#9B3E08" height="16px" width="16px" />;
      default:
        return <CheckCircle2 color="#2D7207" height="16px" width="16px" />;
    }
  };
  const getText = () => {
    switch (props.style) {
      case "do":
        return <span className="text-[#2D7207] ml-1">Do</span>;
      case "dont":
        return <span className="text-[#BF2912] ml-1">Don't</span>;
      case "caution":
        return <span className="text-[#9B3E08] ml-1">Caution</span>;
      default:
        return <span className="text-[#2D7207] ml-1">Do</span>;
    }
  };

  return (
    <div className={clsx(" min-w-[280px]")}>
      <div className="p-5 bg-[#F7F8FA] flex justify-center items-center h-[280px]">
        <Image
          className={clsx("", props.image?.className)}
          alt={props.image?.alt}
          src={props.image?.src}
          hideBorder={true}
          width={props.image?.width}
        />
      </div>
      <div className={clsx("h-2 mb-2", getBG())}></div>
      <div className="flex flex-col">
        <p className="mb-1 flex items-center justify-start text-base font-bold">
          {getIcon()}
          {getText()}
        </p>
        {typeof props.informationCopy === "string" ? (
          <p className="mb-1 flex justify-start text-base text-left">
            {props.informationCopy}
          </p>
        ) : (
        props.informationCopy &&  props.informationCopy()
        )}
      </div>
    </div>
  );
}
