import { Menu as HamburgerIcon } from "lucide-react";
import clsx from "clsx";
import { navLinks } from "./utils/navligationLinkLists";
import SideBar from "../sidebar3/Sidebar";

type gridprop = {
  isOpen: boolean;
  setOpen(_: boolean): void;
};

const Grid = ({ isOpen, setOpen }: gridprop) => {
  return (
    <div>
      <button onClick={() => { setOpen(true); }}>
        <HamburgerIcon aria-label="hamburger-icon"/>
      </button>
      {isOpen && (
        <div
        data-testid='grid'
          className={clsx(
            "w-full h-full fixed bg-black bg-opacity-30 top-0 left-0 cursor-default z-10"
          )}
          onClick={(e) => {
            setOpen(false);
            e.stopPropagation();
          }}
        ></div>
      )}
      <div
        className={clsx(
          "h-full fixed top-0 transition-all duration-500 z-[10]",
          `${isOpen ? "right-0" : "right-[-248px]"}`
        )}
      >
        <SideBar
          isMobile={true}
          sideBarLinks={navLinks}
          showClose={true}
          closeClick={() => {
            setOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default Grid;
