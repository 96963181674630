import { useNavigation } from "../../hooks/useNavigation";

export interface ComponentCardProps {
  label: string;
  to: string;
  imageURL: string;
  description: string;
  verticalAlignment: "flex-start" | "center" | "flex-end";
  horizontalAlignment: "flex-start" | "center" | "flex-end";
  imageWidth?: string;
}

const ComponentCard = ({
  label,
  to,
  imageURL,
  description,
  verticalAlignment = "center",
  horizontalAlignment = "center",
  imageWidth,
}: ComponentCardProps) => {
  const paddingTop = verticalAlignment === "flex-start" ? "24px" : 0;
  const { navigateToURL } = useNavigation();
  return (
    <div className="flex w-full max-h-[412px] items-center justify-center sm:items-start sm:justify-start">
      <div
      data-testid='component-container'
        tabIndex={0}
        onClick={() => {
          navigateToURL(to);
        }}
        className="flex w-full flex-col min-h-[284px] h-full  max-h-[412px] self-center border rounded-lg border-[#C8DAEB] shadow-[0px_2px_3px_0px_rgba(3,59,105,0.10)] cursor-pointer"
      >
        <div
          className="h-[160px] bg-[#F1F4F9] rounded-t-lg flex border-b border-b-[#C8DAEB]"
          style={{
            justifyContent: horizontalAlignment,
            alignItems: verticalAlignment,
            paddingTop: paddingTop,
          }}
          data-testid="componentCardImageContainer"
        >
          <img
            src={imageURL}
            alt={label}
            style={{ maxWidth: "100%", width: imageWidth, maxHeight: "90%", objectFit: "contain" }}
            data-testid="componentCardImage"
          />
        </div>
        <div className="flex flex-col text-left m-[16px]">
          <span
            className="text-xl font-extrabold mb-[8px] "
            data-testid="componentCardLabel"
          >
            {label}
          </span>
          <span
            className="text-base font-normal "
            data-testid="componentCardDescription"
          >
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ComponentCard;
