/* @prettier */
import AnchorLink from '@albertsons/uds/molecule/AnchorLink';
import { ReactNode } from 'react';
import ComponentDetail from '../../components/componentDetail/ComponentDetail';
import { AnchorLinkProps } from './AnchorLink.types';
import anatomy from './assets/anatomy.svg';
import example from './assets/example.svg';
import illustration from './assets/illustration.svg';
import measurements from './assets/measurements.svg';
import usage1 from './assets/usage1.svg';
import usage2 from './assets/usage2.svg';
import usage3 from './assets/usage3.svg';
import usage4 from './assets/usage4.svg';
import usage5 from './assets/usage5.svg';

export const name = 'Anchor Link';

export const description = 'Hyperlinks used with text-based navigation, such as inline text.';

export { illustration };

const About = (): ReactNode => {
  return (
    <div>
      <p className=' text-base text-left'>
        Anchors should be employed when you want an element with a link to visually resemble a
        traditional browser link, typically text with a simple underline.
      </p>
    </div>
  );
};

const selectedItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  const labels = ['Item Management', 'Promotional Management', 'Cost Management', 'Invoice Repository', 'Reports'];

  return (
    <div className="bg-[#fff] w-[250px]">
      {labels.map((label, i) => (
        <AnchorLink
          divider
          key={i}
          label={label}
          status='none'
          selected={i === 1}
        />
      ))}
    </div>
  );

};

render(<App />); 
`;

const disabledItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  const labels = ['Item Management', 'Promotional Management', 'Cost Management', 'Invoice Repository', 'Reports'];

  return (
    <div className="bg-[#fff] w-[250px]">
      {labels.map((label, i) => (
        <AnchorLink
          divider
          key={i}
          label={label}
          status='none'
          disabled={i === 3}
        />
      ))}
    </div>
  );

};

render(<App />); 
`;

const labelItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  return (
    <div className="bg-[#fff] w-[250px]">
      <AnchorLink
        divider
        label='Promotional Management'
        status='none'
      />
    </div>
  );
};

render(<App />); 
`;

const successItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  return (  
    <div className="bg-[#fff] w-[275px]">
      <AnchorLink
        divider
        label='Promotional Management'
        status='success'
      />
    </div>
  );
};

render(<App />); 
`;

const errorItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  return (  
    <div className="bg-[#fff] w-[275px]">
      <AnchorLink
        divider
        label='Promotional Management'
        status='error'
      />
    </div>
  );
};

render(<App />); 
`;

const incompleteItemCode = `
// import React from 'react';
// import AnchorLink from '@albertsons/uds/molecule/AnchorLink';

const App = () => {
  return ( 
    <div className="bg-[#fff] w-[250px]"> 
      <AnchorLink
        divider
        label='Promotional Management'
        status='incomplete'
      />
    </div>
  );
};

render(<App />); 
`;

const statusCopy = (): ReactNode => (
  <p className='max-w-[600px]'>
    Anchor Link has an optional status property to represent the the status of the link or task. The
    available statuses are: “incomplete”, “success”, and “error”. If a status is not defined, no
    icon will render.
  </p>
);

const overviewData = {
  id: 'overview',
  title: 'Overview',
  sections: [
    {
      id: 'about',
      title: 'About',
      visible: true,
      content: About,
    },
    {
      id: 'examples',
      title: 'Examples',
      visible: true,
      image: {
        src: example,
        alt: 'image alt',
        className: 'w-[260px]',
        width: 260,
      },
      copy: '',
      items: [{ description: 'Anchor Link' }],
    },
    {
      id: 'related',
      title: 'Related components',
      visible: true,
      components: [
        {
          id: 'Link',
        },
        {
          id: 'Alert',
        },
        {
          id: 'Tab',
        },
        {
          id: 'Select',
        },
      ],
    },
  ],
};

const specsData = {
  id: 'specs',
  title: 'Specs',
  sections: [
    {
      id: 'anatomy',
      title: 'Anatomy',
      visible: true,
      image: {
        src: anatomy,
        alt: 'anatomy image alt',
        className: 'w-[229px]',
        width: 229,
      },
      items: [
        {
          name: 'Border',
          required: false,
          description: 'Indicates item is selected.',
        },
        {
          name: 'Label',
          required: true,
          description: 'The label of the link.',
        },
        {
          name: 'Icon',
          required: false,
          description: 'Represents the purpose of the anchor link.',
        },
        {
          name: 'Divider',
          required: false,
          description: 'Visual separator between notifications when stacked.',
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Selected',
      visible: true,
      items: [
        {
          optionalCopy: 'When `selected` is true.',
          scope: { AnchorLink },
          code: selectedItemCode,
          noInline: true,
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Disabled',
      visible: true,
      items: [
        {
          optionalCopy: 'When `disabled` is true.',
          scope: { AnchorLink },
          code: disabledItemCode,
          noInline: true,
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Label',
      visible: true,
      items: [
        {
          optionalCopy: 'When `label` prop is set to "Promotional Management".',
          scope: { AnchorLink },
          code: labelItemCode,
          noInline: true,
        },
      ],
    },
    {
      id: 'liveSpec',
      title: 'Status',
      optionalCopy: statusCopy,
      visible: true,
      items: [
        {
          title: 'Success',
          optionalCopy: 'When `status` prop is set to `”success”`.',
          scope: { AnchorLink },
          code: successItemCode,
          noInline: true,
        },
        {
          title: 'Error',
          optionalCopy: 'When `status` prop is set to `”error”`.',
          scope: { AnchorLink },
          code: errorItemCode,
          noInline: true,
        },
        {
          title: 'Registration',
          optionalCopy: 'When `status` prop is set to `”incomplete”`.',
          scope: { AnchorLink },
          code: incompleteItemCode,
          noInline: true,
        },
      ],
    },
    {
      id: 'measurements',
      title: 'Measurements',
      visible: true,
      images: [
        {
          id: 1,
          image: measurements,
          isBig: false,
          alt: 'measurements',
          className: 'w-[254px]',
        },
      ],
    },
  ],
};

const guidelinesData = {
  id: 'guidelines',
  title: 'Guidelines',
  sections: [
    {
      id: 'usage',
      title: 'Usage',
      visible: true,
      tiles: [
        {
          image: {
            src: usage1,
            className: 'border-none w-[250px]',
            alt: '',
            width: 250,
          },
          informationCopy: 'Display exactly one selected Anchor Link at a time.',
          variant: 'half',
          style: 'do',
        },
        {
          image: {
            src: usage2,
            className: 'border-none w-[250px]',
            alt: '',
            width: 250,
          },
          informationCopy: 'Display more than one selected at a time.',
          variant: 'half',
          style: 'dont',
        },
        {
          image: {
            src: usage3,
            className: 'border-none w-[250px]',
            alt: '',
            width: 250,
          },
          informationCopy: 'Keep text as short as possible. ',
          variant: 'half',
          style: 'do',
        },
        {
          image: {
            src: usage4,
            className: 'border-none w-[250px]',
            alt: '',
            width: 250,
          },
          informationCopy: 'Don’t let text get too long or wrap to multiple lines. ',
          variant: 'half',
          style: 'dont',
        },
        {
          image: {
            src: usage5,
            className: 'border-none w-[250px]',
            alt: '',
            width: 250,
          },
          informationCopy: 'Enhance communication with visual icons.',
          variant: 'full',
          style: 'do',
        },
      ],
    },
  ],
};

const playgroundData = {
  title: 'Component testing',
  url: '?path=/story/molecules-anchorlink--anchor-link&shortcuts=false&singleStory=true',
};

const propsData = {
  title: 'Component properties',
  url: 'iframe.html?id=molecules-anchorlink--anchor-link&viewMode=docs&shortcuts=false&singleStory=true',
};

const AnchorLinkData = ({ activeTab, navigateToURL }: AnchorLinkProps) => {
  return (
    <>
      <ComponentDetail
        developed
        title='Anchor Link'
        subtitle='Hyperlinks used with text-based navigation, such as inline text.'
        src={illustration}
        imageWidth='160px'
        imageAlt='Anchor Link Component'
        activeTab={activeTab}
        navigateToURL={navigateToURL}
        overviewData={overviewData}
        specsData={specsData}
        guidelinesData={guidelinesData}
        playgroundData={playgroundData}
        propsData={propsData}
      />
    </>
  );
};

export default AnchorLinkData;
