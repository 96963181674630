/** @prettier */

import { ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

export function FooterList({ heading, children }: { heading?: ReactNode; children?: ReactNode }) {
  return (
    <div
      style={css({
        display: 'flex',
        flexDirection: 'column',
        gap: V.smallCornerRadius,
        fontFamily: V.sansSerif,
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        color: V.secondary201DarkText,
        on: $ => [
          $('@media (prefers-color-scheme: dark)', {
            color: V.primary106White,
          }),
        ],
      })}
    >
      <div style={{ fontWeight: 700, marginBottom: V.smallCornerRadius }}>{heading}</div>
      {children}
    </div>
  );
}
