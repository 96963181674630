import { ReactNode, Children, isValidElement, cloneElement } from "react";

interface TabsProps {
  children: ReactNode;
  activeTab: string;
}

const Tabs = ({ children, activeTab }: TabsProps) => {
  const childrenWithProps = Children.map(children, (child: any) => {
    const isSelected = activeTab === child?.props.url;
    if (isValidElement(child)) {
      return cloneElement<any>(child, {
        selected: isSelected,
      });
    }
    return child;
  });

  return (
    <div className="flex flex-wrap w-full border-b border-[#C8DAEB] pb-[2px]">
      {childrenWithProps}
    </div>
  );
};

export default Tabs;
