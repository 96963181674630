import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "a004155f-a2d4-4699-ae0d-3f72b5f5cd61" || "",
    authority:
      "https://login.microsoftonline.com/b7f604a0-00a9-4188-9248-42f3a5aac2e9",
    redirectUri: `${window.location.origin}/uds-doc/redirect`,
  },
};
