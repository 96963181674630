import { DetailBanner } from "../../components/detail-banner";
import illustration from "./illustration.svg";

export default function AccessibilityPage() {
  return (
    <section style={{ textAlign: "left", padding: "0 2rem" }} data-testid="legal-container">
      <DetailBanner
        heading={<h1 style={{ all: "unset" }}>Accessibility</h1>}
        description={<p style={{ all:"unset" }}>How the Unified Design System team ensures inclusivity and usability through accessible design practices</p>}
        graphic={<img src={illustration} alt="Person leaning on the letter A" />} />
      <div className="max-w-[600px] mt-[70px]">
        <h3 className="  font-normal  text-[28px] leading-[36px] pb-6">
          Digital Accessibility Statement
        </h3>
        <div className="mb-14">
          <h5 className="   font-extrabold  text-[20px] leading-[28px] pb-2">
            Albertsons Commitment to Accessibility
          </h5>
          <p className="text-base font-normal  pb-4">
            It is the commitment of the Albertsons Companies ("Albertsons") to
            provide websites, mobile applications, and other digital content
            that are accessible to all customers, including those with
            disabilities. We have adopted the Web Content Accessibility
            Guidelines (WCAG) 2.0 Level AA as our Accessibility Standard and are
            continually working to maintain our web pages and apps in
            substantial conformance with that Standard, as well as to provide
            the best possible user experience for everyone. We do this through
            our regular testing as well as our partnership with the American
            Foundation for the Blind.
          </p>
        </div>
        <div className="mb-14">
          <h5 className="  font-extrabold  text-[20px] leading-[28px] pb-2">
            Software recommendations and settings
          </h5>
          <p className="text-base font-normal  pb-4">
            We are committed to providing websites, mobile applications and
            digital content that is accessible to the widest possible audience,
            regardless of technology or ability. We are actively working to
            increase the accessibility and usability of our websites and mobile
            applications and in doing so adhere to the available standards and
            guidelines. We follow the W3C's globally recognized standards to
            provide content that is compatible with the most popular Assistive
            Technologies, Operating Systems, and Web Browsers. We recommend
            using the most current version (or one version prior) of the
            Assistive Technology(s), along with a current version of the
            Operating System and browsers listed below.
          </p>
          <p className="text-base font-normal ">
            Please refer to the product sites of the Assistive Technologies for
            information on how to obtain them.
          </p>
        </div>
        <div className="mb-10">
          <h5 className="  font-extrabold  text-[20px] leading-[28px] mb-2">
            Assistive Technologies
          </h5>
          <p className="text-base font-normal  mb-10">
            We recommend the following device and assistive technology
            combinations for the best user experience:
          </p>
          <p className="text-base font-bold  mb-2">
            Mobile devices:
          </p>
          <ul className="list-disc list-inside mt-2 mb-10">
            <li>Android: TalkBack</li>
            <li>Apple: VoiceOver (iPhone and iPad)</li>
          </ul>
          <p className="text-base font-bold  mb-2">Desktop:</p>
          <ul className="list-disc list-inside mt-2">
            <li>JAWS: Firefox</li>
            <li>NVDA: Firefox or Chrome</li>
            <li>macOS: VoiceOver with Safari</li>
          </ul>
        </div>
        <div>
          <p className="text-base font-normal  mb-10">
            We welcome your feedback questions or concerns about the
            accessibility of any of our websites, mobile applications, or other
            digital assets. Please contact accessibilityfeedback@albertsons.com
            or call Accessibility Support at 877.635.4833. We make every effort
            to respond within three business days.
          </p>
          <p className="text-base font-normal ">
            Effective: February 2024
          </p>
        </div>
      </div>
    </section>
  );
}
