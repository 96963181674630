/* @prettier */
import { createHooks } from '@css-hooks/react';

export const { styleSheet, css } = createHooks({
  hooks: ({ and, not, or }) => ({
    // Pseudo-classes: basic
    '&:active': and('&:active', not(or('&:disabled', '&[aria-disabled="true"]'))),
    '&:disabled': or('&:disabled', '&[aria-disabled="true"]'),
    '&:focus-visible': '&:focus-visible',
    '&:focus-visible-within': '&:has(:focus-visible)',
    '&:hover': and(
      '@media (hover:hover)', // https://github.com/w3c/csswg-drafts/issues/7544
      '&:hover',
      not(or('&:disabled', '&[aria-disabled="true"]')),
    ),
    '&:intent': and(
      or('&:focus-visible', '&:hover'),
      not(or('&:disabled', '&[aria-disabled="true"]')),
    ),

    // Pseudo-classes: group
    '.group:disabled &': or('.group:disabled &', '.group[aria-disabled="true"] &'),

    // Responsive: color schemes
    '@media (prefers-color-scheme: dark)': and(
      '[data-theme="dark"] &',
      or(not('[data-pathname] &'), '[data-pathname="/"] &'),
    ),

    // Responsive: basic container queries
    '@container (width < 32rem)': '@container (width < 32rem)',
    '@container (width < 40rem)': '@container (width < 40rem)',
    '@container (width < 44rem)': '@container (width < 44rem)',
    '@container (width >= 32rem)': '@container (width >= 32rem)',
    '@container (width >= 44rem)': '@container (width >= 44rem)',
    '@container (width >= 80rem)': '@container (width >= 80rem)',

    // Responsive: group container queries
    '@container group (width < 44rem)': '@container group (width < 44rem)',

    // Responsive: screens
    '@screen (mobile)': '@media (width < 48em)', // width < 768px
    '@screen (tablet b)': '@media (48em <= width < 64em)', // 768px <= width < 1024px
    '@screen (tablet a)': '@media (64em <= width < 90em)', // 1024px <= width < 1440px
    '@screen (desktop b)': '@media (90em <= width < 120em)', // 1440px <= width < 1920px
    '@screen (desktop a)': '@media (120em <= width)', // 1920px <= width
  }),
});
