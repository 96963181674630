import { FC, ReactNode } from "react";
import * as V from "../../variables";

export const HomeCardGraphicContainer: FC<{children?:ReactNode}> = function HomeCardGraphicContainer({children}) {
  return (
    <div
      style={{
        display: "grid",
        borderRadius: V.bigCornerRadius,
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  )
};