import React from "react";
import { navLinks } from "../../routes/header/utils/navligationLinkLists";
import { RelatedProps } from "./Related.types";
import ComponentCard from "../componentCard";
import { SideBarLink } from "../../routes/sidebar3/Sidebar.types";

export default function Related(props: RelatedProps) {
  const components = navLinks.filter((item) => item.label === "Components")[0];
  const generateCards = () => {
    return props?.components?.map((id, index) => {
      return components.subMenu.map((subMenu: SideBarLink, index) => {
        // eslint-disable-next-line array-callback-return
        return subMenu.children?.map((item, index) => {
          const indexKey = index;
          if (id.id.toLowerCase() === item.label.toLowerCase()) {
            return <ComponentCard key={indexKey} {...item} />;
          }
        });
      });
    });
  };

  return (
    <section className="text-left flex flex-col">
      <div
        data-testid="example-copy"
        className="flex flex-col items-start mb-6"
      >
        <h3 className=" text-[28px] leading-9  font-normal ">
          {props.title}
        </h3>
      </div>
      <div className="mb-[10px] grid gap-6 auto-rows-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 items-center justify-center">
        {generateCards()}
      </div>
    </section>
  );
}
