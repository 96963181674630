import React, { FC, SVGProps } from "react";
import { css } from "../css";

const UDSLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="702" height="300" viewBox="0 0 702 300" fill="none" { ...props }>
      <path d="M481.89 279.79C479.31 277.9 477.88 275.33 477.57 272.07H482.24C482.46 274.07 483.4 275.69 485.06 276.93C486.73 278.17 488.97 278.79 491.78 278.79C494.25 278.79 496.18 278.2 497.59 277.03C499 275.86 499.7 274.4 499.7 272.66C499.7 271.44 499.31 270.44 498.53 269.66C497.75 268.88 496.77 268.26 495.58 267.8C494.39 267.34 492.78 266.85 490.74 266.33C488.1 265.64 485.96 264.94 484.31 264.24C482.67 263.55 481.27 262.51 480.12 261.14C478.97 259.77 478.4 257.94 478.4 255.63C478.4 253.89 478.92 252.28 479.96 250.81C481 249.33 482.47 248.16 484.37 247.29C486.27 246.42 488.44 245.99 490.86 245.99C494.67 245.99 497.74 246.96 500.08 248.89C502.42 250.82 503.67 253.49 503.85 256.88H499.31C499.18 254.79 498.37 253.11 496.88 251.83C495.39 250.55 493.34 249.91 490.75 249.91C488.46 249.91 486.6 250.45 485.17 251.54C483.74 252.63 483.03 253.97 483.03 255.58C483.03 256.97 483.45 258.11 484.3 259C485.14 259.89 486.19 260.59 487.45 261.09C488.7 261.59 490.39 262.14 492.51 262.75C495.06 263.45 497.1 264.12 498.61 264.77C500.12 265.42 501.42 266.38 502.51 267.64C503.59 268.9 504.15 270.57 504.2 272.66C504.2 274.57 503.68 276.28 502.64 277.78C501.6 279.28 500.15 280.46 498.29 281.33C496.43 282.2 494.29 282.63 491.86 282.63C487.79 282.63 484.47 281.68 481.9 279.79H481.89Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M541.97 246.52L520.94 298.86H516.2L523.08 281.91L508.54 246.52H513.54L525.61 276.89L537.3 246.52H541.97Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M550.37 279.79C547.79 277.9 546.36 275.33 546.05 272.07H550.72C550.94 274.07 551.88 275.69 553.54 276.93C555.21 278.17 557.45 278.79 560.26 278.79C562.73 278.79 564.66 278.2 566.07 277.03C567.48 275.86 568.18 274.4 568.18 272.66C568.18 271.44 567.79 270.44 567.01 269.66C566.23 268.88 565.25 268.26 564.06 267.8C562.87 267.34 561.26 266.85 559.22 266.33C556.58 265.64 554.44 264.94 552.79 264.24C551.15 263.55 549.75 262.51 548.6 261.14C547.45 259.77 546.88 257.94 546.88 255.63C546.88 253.89 547.4 252.28 548.44 250.81C549.48 249.33 550.95 248.16 552.85 247.29C554.75 246.42 556.92 245.99 559.34 245.99C563.15 245.99 566.22 246.96 568.56 248.89C570.9 250.82 572.15 253.49 572.33 256.88H567.79C567.66 254.79 566.85 253.11 565.36 251.83C563.87 250.55 561.82 249.91 559.23 249.91C556.94 249.91 555.08 250.45 553.65 251.54C552.22 252.63 551.51 253.97 551.51 255.58C551.51 256.97 551.93 258.11 552.78 259C553.62 259.89 554.67 260.59 555.93 261.09C557.18 261.59 558.87 262.14 560.99 262.75C563.54 263.45 565.58 264.12 567.09 264.77C568.6 265.42 569.9 266.38 570.99 267.64C572.07 268.9 572.63 270.57 572.68 272.66C572.68 274.57 572.16 276.28 571.12 277.78C570.08 279.28 568.63 280.46 566.77 281.33C564.91 282.2 562.77 282.63 560.34 282.63C556.27 282.63 552.95 281.68 550.38 279.79H550.37Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M587.15 250.36V272.46C587.15 274.63 587.56 276.13 588.38 276.96C589.2 277.79 590.65 278.2 592.73 278.2H596.88V282.11H592.01C588.81 282.11 586.43 281.36 584.87 279.86C583.31 278.36 582.53 275.9 582.53 272.46V250.36H577.6V246.51H582.53V237.58H587.14V246.51H596.88V250.36H587.14H587.15Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M636.29 266.07H606.88C607.01 268.77 607.66 271.07 608.83 272.98C610 274.89 611.53 276.34 613.44 277.31C615.34 278.29 617.42 278.78 619.67 278.78C622.61 278.78 625.09 278.06 627.1 276.63C629.11 275.2 630.44 273.26 631.09 270.83H635.89C635.02 274.31 633.17 277.14 630.34 279.34C627.51 281.53 623.95 282.63 619.66 282.63C616.33 282.63 613.34 281.88 610.7 280.38C608.06 278.88 605.99 276.75 604.5 273.99C603.01 271.23 602.26 268 602.26 264.31C602.26 260.62 603 257.38 604.47 254.6C605.94 251.82 608 249.69 610.64 248.21C613.28 246.73 616.29 245.99 619.66 245.99C623.03 245.99 625.99 246.73 628.52 248.21C631.05 249.69 633 251.68 634.36 254.17C635.72 256.67 636.4 259.44 636.4 262.48C636.4 264.04 636.36 265.24 636.27 266.06L636.29 266.07ZM630.22 255.58C629.12 253.71 627.61 252.3 625.71 251.34C623.8 250.38 621.73 249.91 619.48 249.91C616.1 249.91 613.23 251 610.85 253.17C608.47 255.34 607.15 258.41 606.89 262.36H631.82C631.86 259.71 631.33 257.45 630.23 255.58H630.22Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M697.09 249.809C699.71 252.439 701.02 256.249 701.02 261.249V282.109H696.54V261.769C696.54 257.899 695.61 254.949 693.75 252.909C691.89 250.869 689.36 249.849 686.15 249.849C682.94 249.849 680.15 250.959 678.16 253.169C676.17 255.389 675.17 258.599 675.17 262.819V282.109H670.69V261.769C670.69 257.899 669.76 254.949 667.9 252.909C666.04 250.869 663.48 249.849 660.24 249.849C657 249.849 654.24 250.959 652.25 253.169C650.26 255.389 649.26 258.599 649.26 262.819V282.109H644.72V246.519H649.26V252.649C650.39 250.429 651.99 248.749 654.06 247.599C656.14 246.449 658.45 245.869 661.01 245.869C664.08 245.869 666.78 246.609 669.09 248.089C671.4 249.569 673.08 251.739 674.12 254.609C675.07 251.789 676.71 249.619 679.02 248.119C681.33 246.619 683.96 245.869 686.91 245.869C691.06 245.869 694.45 247.179 697.07 249.809H697.09Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M243.93 254.629C245.4 251.869 247.42 249.739 250 248.239C252.57 246.739 255.49 245.989 258.73 245.989C261.97 245.989 264.66 246.749 267.17 248.269C269.68 249.789 271.52 251.769 272.69 254.199V233.859H277.23V282.089H272.69V274.139C271.61 276.619 269.83 278.649 267.37 280.229C264.9 281.819 262 282.609 258.67 282.609C255.34 282.609 252.51 281.849 249.94 280.329C247.36 278.809 245.35 276.659 243.9 273.879C242.45 271.099 241.73 267.879 241.73 264.229C241.73 260.579 242.47 257.379 243.94 254.619L243.93 254.629ZM270.94 256.749C269.77 254.579 268.18 252.909 266.17 251.729C264.16 250.549 261.94 249.969 259.52 249.969C257.1 249.969 254.76 250.539 252.77 251.659C250.78 252.789 249.21 254.429 248.06 256.579C246.91 258.729 246.34 261.279 246.34 264.239C246.34 267.199 246.91 269.709 248.06 271.899C249.21 274.089 250.78 275.769 252.77 276.919C254.76 278.069 257.01 278.649 259.52 278.649C262.03 278.649 264.16 278.059 266.17 276.889C268.18 275.719 269.77 274.039 270.94 271.869C272.11 269.699 272.69 267.179 272.69 264.309C272.69 261.439 272.11 258.919 270.94 256.749Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M319.56 266.07H290.15C290.28 268.77 290.93 271.07 292.1 272.98C293.27 274.89 294.8 276.34 296.71 277.31C298.61 278.29 300.69 278.78 302.94 278.78C305.88 278.78 308.36 278.06 310.37 276.63C312.38 275.2 313.71 273.26 314.36 270.83H319.16C318.29 274.31 316.44 277.14 313.61 279.34C310.78 281.53 307.22 282.63 302.93 282.63C299.6 282.63 296.61 281.88 293.97 280.38C291.33 278.88 289.26 276.75 287.77 273.99C286.28 271.23 285.53 268 285.53 264.31C285.53 260.62 286.27 257.38 287.74 254.6C289.21 251.82 291.27 249.69 293.91 248.21C296.55 246.73 299.56 245.99 302.93 245.99C306.3 245.99 309.26 246.73 311.79 248.21C314.32 249.69 316.27 251.68 317.63 254.17C318.99 256.67 319.67 259.44 319.67 262.48C319.67 264.04 319.63 265.24 319.54 266.06L319.56 266.07ZM313.49 255.58C312.39 253.71 310.88 252.3 308.98 251.34C307.08 250.38 305 249.91 302.75 249.91C299.37 249.91 296.5 251 294.12 253.17C291.74 255.34 290.42 258.41 290.16 262.36H315.09C315.13 259.71 314.6 257.45 313.5 255.58H313.49Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M330.24 279.79C327.66 277.9 326.23 275.33 325.92 272.07H330.59C330.81 274.07 331.75 275.69 333.41 276.93C335.08 278.17 337.32 278.79 340.13 278.79C342.6 278.79 344.53 278.2 345.94 277.03C347.35 275.86 348.05 274.4 348.05 272.66C348.05 271.44 347.66 270.44 346.88 269.66C346.1 268.88 345.12 268.26 343.93 267.8C342.74 267.34 341.13 266.85 339.09 266.33C336.45 265.64 334.31 264.94 332.66 264.24C331.01 263.55 329.62 262.51 328.47 261.14C327.32 259.77 326.75 257.94 326.75 255.63C326.75 253.89 327.27 252.28 328.31 250.81C329.35 249.33 330.82 248.16 332.72 247.29C334.62 246.42 336.79 245.99 339.21 245.99C343.02 245.99 346.09 246.96 348.43 248.89C350.77 250.82 352.02 253.49 352.2 256.88H347.66C347.53 254.79 346.72 253.11 345.23 251.83C343.74 250.55 341.69 249.91 339.1 249.91C336.81 249.91 334.95 250.45 333.52 251.54C332.09 252.63 331.38 253.97 331.38 255.58C331.38 256.97 331.8 258.11 332.65 259C333.49 259.89 334.54 260.59 335.8 261.09C337.05 261.59 338.74 262.14 340.86 262.75C343.41 263.45 345.45 264.12 346.96 264.77C348.47 265.42 349.77 266.38 350.86 267.64C351.94 268.9 352.5 270.57 352.55 272.66C352.55 274.57 352.03 276.28 350.99 277.78C349.95 279.28 348.5 280.46 346.64 281.33C344.78 282.2 342.64 282.63 340.21 282.63C336.14 282.63 332.82 281.68 330.24 279.79Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M361.04 238.82C360.39 238.17 360.07 237.36 360.07 236.41C360.07 235.46 360.39 234.66 361.04 234.03C361.69 233.4 362.47 233.08 363.38 233.08C364.29 233.08 365.07 233.4 365.72 234.03C366.37 234.66 366.69 235.45 366.69 236.41C366.69 237.37 366.37 238.17 365.72 238.82C365.07 239.47 364.29 239.8 363.38 239.8C362.47 239.8 361.69 239.47 361.04 238.82ZM365.65 246.52V282.11H361.11V246.52H365.65Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M399.54 248.34C402.01 249.9 403.78 251.88 404.86 254.27V246.51H409.4V283.01C409.4 286.27 408.7 289.16 407.29 291.68C405.88 294.2 403.92 296.15 401.41 297.51C398.9 298.88 396.04 299.56 392.84 299.56C388.3 299.56 384.52 298.47 381.51 296.3C378.5 294.13 376.63 291.17 375.89 287.43H380.37C381.15 289.91 382.63 291.88 384.82 293.36C387 294.84 389.68 295.58 392.84 295.58C395.13 295.58 397.19 295.09 399.01 294.11C400.83 293.13 402.26 291.69 403.3 289.78C404.34 287.87 404.86 285.61 404.86 283V274.2C403.73 276.64 401.95 278.64 399.5 280.23C397.05 281.82 394.17 282.61 390.83 282.61C387.49 282.61 384.67 281.85 382.1 280.33C379.52 278.81 377.51 276.66 376.06 273.88C374.61 271.1 373.89 267.88 373.89 264.23C373.89 260.58 374.61 257.38 376.06 254.62C377.51 251.86 379.52 249.73 382.1 248.23C384.67 246.73 387.59 245.98 390.83 245.98C394.07 245.98 397.06 246.76 399.53 248.33L399.54 248.34ZM403.11 256.75C401.94 254.58 400.35 252.91 398.34 251.73C396.33 250.55 394.11 249.97 391.69 249.97C389.27 249.97 386.93 250.54 384.94 251.66C382.95 252.79 381.38 254.43 380.23 256.58C379.08 258.73 378.51 261.28 378.51 264.24C378.51 267.2 379.08 269.71 380.23 271.9C381.38 274.09 382.95 275.77 384.94 276.92C386.93 278.07 389.18 278.65 391.69 278.65C394.2 278.65 396.33 278.06 398.34 276.89C400.35 275.72 401.94 274.04 403.11 271.87C404.28 269.7 404.86 267.18 404.86 264.31C404.86 261.44 404.28 258.92 403.11 256.75Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M446.8 249.809C449.48 252.439 450.82 256.249 450.82 261.249V282.109H446.34V261.769C446.34 257.899 445.38 254.949 443.45 252.909C441.52 250.869 438.89 249.849 435.56 249.849C432.23 249.849 429.41 250.939 427.38 253.109C425.34 255.279 424.33 258.479 424.33 262.689V282.109H419.79V246.519H424.33V252.579C425.46 250.409 427.08 248.739 429.2 247.589C431.32 246.439 433.72 245.859 436.41 245.859C440.65 245.859 444.11 247.169 446.8 249.799V249.809Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M36.5999 246.52V282.11H32.0599V275.85C31.0199 278.07 29.4199 279.76 27.2599 280.93C25.0999 282.1 22.6699 282.69 19.9899 282.69C15.7499 282.69 12.2899 281.38 9.59995 278.75C6.91995 276.12 5.56995 272.31 5.56995 267.31V246.52H10.0499V266.79C10.0499 270.66 11.0099 273.61 12.9399 275.66C14.8699 277.7 17.4899 278.72 20.8299 278.72C24.1699 278.72 26.9699 277.64 29.0099 275.46C31.0399 273.29 32.0599 270.09 32.0599 265.88V246.52H36.5999Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M73.99 249.809C76.67 252.439 78.02 256.249 78.02 261.249V282.109H73.54V261.769C73.54 257.899 72.58 254.949 70.65 252.909C68.72 250.869 66.09 249.849 62.76 249.849C59.43 249.849 56.61 250.939 54.58 253.109C52.55 255.279 51.53 258.479 51.53 262.689V282.109H46.99V246.519H51.53V252.579C52.66 250.409 54.28 248.739 56.4 247.589C58.52 246.439 60.92 245.859 63.61 245.859C67.85 245.859 71.31 247.169 74 249.799L73.99 249.809Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M88.0099 238.82C87.3599 238.17 87.0399 237.36 87.0399 236.41C87.0399 235.46 87.3599 234.66 88.0099 234.03C88.6599 233.4 89.4399 233.08 90.3499 233.08C91.2599 233.08 92.0399 233.4 92.6899 234.03C93.3399 234.66 93.6599 235.45 93.6599 236.41C93.6599 237.37 93.3399 238.17 92.6899 238.82C92.0399 239.47 91.2599 239.8 90.3499 239.8C89.4399 239.8 88.6599 239.47 88.0099 238.82ZM92.6199 246.52V282.11H88.0799V246.52H92.6199Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M116.9 250.36H108.65V282.1H104.11V250.36H99.1799V246.51H104.11V244.03C104.11 240.16 105.09 237.32 107.06 235.49C109.03 233.66 112.22 232.75 116.64 232.75V236.66C113.7 236.66 111.63 237.23 110.44 238.35C109.25 239.48 108.65 241.37 108.65 244.02V246.5H116.9V250.35V250.36Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M123.98 238.82C123.33 238.17 123.01 237.36 123.01 236.41C123.01 235.46 123.33 234.66 123.98 234.03C124.63 233.4 125.41 233.08 126.32 233.08C127.23 233.08 128.01 233.4 128.66 234.03C129.31 234.66 129.63 235.45 129.63 236.41C129.63 237.37 129.31 238.17 128.66 238.82C128.01 239.47 127.23 239.8 126.32 239.8C125.41 239.8 124.63 239.47 123.98 238.82ZM128.59 246.52V282.11H124.05V246.52H128.59Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M170.85 266.07H141.44C141.57 268.77 142.22 271.07 143.39 272.98C144.56 274.89 146.09 276.34 148 277.31C149.9 278.29 151.98 278.78 154.23 278.78C157.17 278.78 159.65 278.06 161.66 276.63C163.67 275.2 165 273.26 165.65 270.83H170.45C169.58 274.31 167.73 277.14 164.9 279.34C162.07 281.53 158.51 282.63 154.22 282.63C150.89 282.63 147.9 281.88 145.26 280.38C142.62 278.88 140.55 276.75 139.06 273.99C137.57 271.23 136.82 268 136.82 264.31C136.82 260.62 137.56 257.38 139.03 254.6C140.5 251.82 142.56 249.69 145.2 248.21C147.84 246.73 150.85 245.99 154.22 245.99C157.59 245.99 160.55 246.73 163.08 248.21C165.61 249.69 167.56 251.68 168.92 254.17C170.28 256.67 170.96 259.44 170.96 262.48C170.96 264.04 170.92 265.24 170.83 266.06L170.85 266.07ZM164.78 255.58C163.68 253.71 162.17 252.3 160.27 251.34C158.37 250.38 156.29 249.91 154.04 249.91C150.66 249.91 147.79 251 145.41 253.17C143.03 255.34 141.71 258.41 141.45 262.36H166.38C166.42 259.71 165.89 257.45 164.79 255.58H164.78Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M179.42 254.629C180.89 251.869 182.91 249.739 185.49 248.239C188.06 246.739 190.98 245.989 194.22 245.989C197.46 245.989 200.15 246.749 202.66 248.269C205.17 249.789 207.01 251.769 208.18 254.199V233.859H212.72V282.089H208.18V274.139C207.1 276.619 205.32 278.649 202.86 280.229C200.39 281.819 197.49 282.609 194.16 282.609C190.83 282.609 188 281.849 185.43 280.329C182.85 278.809 180.84 276.659 179.39 273.879C177.94 271.099 177.22 267.879 177.22 264.229C177.22 260.579 177.96 257.379 179.43 254.619L179.42 254.629ZM206.42 256.749C205.25 254.579 203.66 252.909 201.65 251.729C199.64 250.549 197.42 249.969 195 249.969C192.58 249.969 190.24 250.539 188.25 251.659C186.26 252.789 184.69 254.429 183.54 256.579C182.39 258.729 181.82 261.279 181.82 264.239C181.82 267.199 182.39 269.709 183.54 271.899C184.69 274.089 186.26 275.769 188.25 276.919C190.24 278.069 192.49 278.649 195 278.649C197.51 278.649 199.64 278.059 201.65 276.889C203.66 275.719 205.25 274.039 206.42 271.869C207.59 269.699 208.17 267.179 208.17 264.309C208.17 261.439 207.59 258.919 206.42 256.749Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M640.05 100.549C640.4 101.059 640.79 101.559 641.18 102.039C645.4 107.149 655.5 112.599 661.32 115.609C671.32 120.769 683.71 128.169 690.65 137.379C696.07 144.869 698.78 153.359 698.78 162.599C698.78 175.529 694.2 186.699 684.99 195.779C675.59 205.059 663.92 209.339 650.75 209.339C641.79 209.339 633.25 207.369 625.16 203.509C614.31 198.329 609.86 192.259 602.09 183.989L620.5 163.119L624.48 167.619C631.1 175.089 639.6 181.479 650 181.479C655.75 181.479 660.74 179.749 665.01 175.869C668.74 172.479 670.92 168.469 670.92 163.389C670.92 159.419 669.68 156.009 667.12 152.959C663.03 148.099 651.54 142.159 645.91 139.279C637.08 134.769 626.56 128.489 619.88 120.609L640.05 100.539V100.549Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M341.21 144.971C341.68 137.861 341.64 130.201 341.64 125.451V54.3906H370.82V115.441L341.21 144.971Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M485.05 79.06C476.03 79.06 467.61 81.26 459.81 85.77C455.88 88.05 452.4 90.77 449.34 93.92L381.71 161.15L363.56 179.9C356.56 186.95 349.55 194.98 340.98 200.2C329.84 206.99 316.85 209.33 303.95 209.33C291.05 209.33 278.01 206.97 266.8 200.21C256.62 194.07 249.04 185.43 244.1 174.61C237.9 161.01 236.81 142.24 236.81 127.48V54.39H265.46V125.45C265.46 134.24 265.39 148.64 267.86 157.08C270.02 164.47 274.28 170.28 280.62 174.62C287.53 179.35 295.49 181.21 303.8 181.21C320.42 181.21 327.44 175.22 338.37 164.23C342.36 160.21 345.56 156.73 345.56 156.73L381.69 120.67L428.5 74.1L428.99 73.61C444.03 58.63 462.3 50.95 483.61 50.95H485.02C496.36 51.15 507.29 53.59 517.41 58.84C523.71 62.12 529.31 66.32 534.29 71.22V0H562.95V104.57L535.34 132.03V130.84C535.35 116.53 530.87 104.11 520.78 93.83C510.93 83.8 499.07 79.06 485.03 79.06H485.05Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M485.05 209.31L483.11 209.33C461.65 209.33 443.28 201.45 428.29 186.11C418.14 175.73 411.48 163.88 408.16 150.69L434.94 124.06C434.69 126.13 434.56 128.22 434.56 130.35C434.56 139.53 436.98 148.1 441.48 156.09C445.99 164.09 452.08 170.52 460.01 175.16C467.72 179.67 475.99 181.99 484.92 181.99H485.05C504.6 181.93 513.75 169.61 527.61 155.83L614.66 70.8897C620.76 64.9397 625.97 59.0097 633.85 55.2497C640.05 52.2897 646.91 50.9297 654.2 50.9297C670.89 50.9297 685.38 59.9897 697.12 71.1397L701.01 74.8297L681.91 94.5097L678.11 90.8197C671.56 84.4797 662.88 78.2497 653.37 78.2497C644.88 78.2497 641.17 83.3297 635.88 88.5897L562.97 161.13V205.86H534.31V189.61C528.94 194.48 522.99 198.65 516.38 201.87C506.53 206.67 495.96 209.03 485.05 209.29V209.31Z" style={css({ fill: "#169FDA", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#B9E6FE" })] })}/>
      <path d="M75.82 86.66C81.57 108.42 100.26 125.58 109.35 144.95L109.44 145.11L109.54 144.95C118.63 125.58 137.32 108.43 143.07 86.66C154.33 53.12 126.07 26.29 109.55 0.17L109.45 0L109.34 0.17C92.82 26.29 64.56 53.12 75.82 86.66Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <path d="M109.4 159.501C107.28 154.551 102.62 145.851 102.62 145.851C102.62 145.851 85.5301 105.551 32.2901 106.981C16.7201 107.401 12.1001 107.691 9.33005 107.751C6.56005 107.811 4.44005 107.881 1.81005 107.791L1.68005 108.151C1.44005 108.861 0.87005 111.181 0.24005 118.591C-0.79995 130.751 0.95005 146.561 18.83 164.751C33.12 179.291 47.7801 180.181 69.6801 181.481C91.5701 182.771 96.7001 188.361 101.11 193.241C104.93 197.481 108.95 208.721 109.37 210.381L109.41 210.511L109.46 210.381C109.87 208.721 113.89 197.471 117.71 193.241C122.13 188.361 127.25 182.771 149.14 181.481C171.03 180.181 185.69 179.291 199.98 164.751C217.85 146.561 219.61 130.751 218.57 118.591C217.93 111.181 217.36 108.861 217.13 108.151L217 107.791C214.36 107.881 212.24 107.811 209.47 107.751C206.7 107.691 202.09 107.391 186.51 106.981C133.28 105.551 116.19 145.851 116.19 145.851C116.19 145.851 111.53 154.551 109.4 159.501Z" style={css({ fill: "#04559F", on: $ => [$("@media (prefers-color-scheme: dark)", { fill: "#538DD3" })] })}/>
      <defs>
        <clipPath id="clip0_6056_37191">
          <rect width="701.02" height="299.57" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default UDSLogo as FC<SVGProps<SVGSVGElement>>;