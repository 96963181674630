/** @prettier */

import { FC, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

export const FooterLink: FC<{ children?: ReactNode }> = function FooterLink({ children }) {
  return (
    <span
      style={css({
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.25rem',
        color: V.primary101Brand,
        outlineWidth: '0.125rem',
        outlineStyle: 'solid',
        outlineColor: 'transparent',
        borderRadius: '0.25rem',
        fontWeight: 600,
        textDecoration: 'underline',
        cursor: 'pointer',
        on: ($, { and, not, or }) => [
          $('@media (prefers-color-scheme: dark)', {
            color: V.primary107BrandUltralight,
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:hover'), {
            background: V.tertiary305Blue,
          }),
          $(and('@media (prefers-color-scheme: dark)', '&:hover'), {
            background: V.extended508BrandBlue500,
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:active'), {
            background: V.tertiary304Blue,
          }),
          $(and('@media (prefers-color-scheme: dark)', '&:active'), {
            background: V.extended508BrandBlue700,
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), or('&:hover', '&:active')), {
            color: V.primary102BrandDark,
          }),
          $(and('@media (prefers-color-scheme: dark)', or('&:hover', '&:active')), {
            color: '#fff',
          }),
          $(and(not('@media (prefers-color-scheme: dark)'), '&:focus-visible-within'), {
            outlineColor: V.primary101Brand,
          }),
          $(and('@media (prefers-color-scheme: dark)', '&:focus-visible-within'), {
            outlineColor: V.extended508BrandBlue300,
          }),
        ],
      })}
    >
      {children}
    </span>
  );
};
