/** @prettier */
import React, { createContext, useState } from 'react';

type Props = {
  searchInput: string;
  setSearchInput: (value: string) => void;
};

export const SearchContext = createContext<Props>({
  searchInput: '',
  setSearchInput: () => void {},
});

export const SearchProvider = ({ children }: React.PropsWithChildren) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <SearchContext.Provider
      value={{
        searchInput,
        setSearchInput,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
