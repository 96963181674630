/* @prettier */

import { FC, ReactNode } from 'react';
import { css } from '../../css';

export const HomeCardPadding: FC<{ children?: ReactNode }> = function HomeCardPadding({
  children,
}) {
  return (
    <div style={{ containerType: 'inline-size' }}>
      <div
        style={css({
          padding: '1.5rem',
          on: $ => [
            $('@container (width < 32rem)', {
              padding: '1rem',
            }),
            $('@container (width >= 32rem)', {
              padding: '1.5rem',
            }),
            $('@container (width >= 44rem)', {
              padding: '1.875rem',
            }),
          ],
        })}
      >
        {children}
      </div>
    </div>
  );
};
