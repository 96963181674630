/** @prettier */

import { FC } from 'react';
import { css } from '../../css';
import * as V from '../../variables';
import { AlbertsonsLogo } from './albertsons-logo';

export const FooterLeft: FC = function FooterLeft() {
  return (
    <div
      style={css({
        display: 'flex',
        alignItems: 'start',
        gap: V.bigCornerRadius,
        color: V.secondary201DarkText,
        fontFamily: V.sansSerif,
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        minWidth: '17.5rem',
        maxWidth: '22.5rem',
        on: $ => [
          $('@media (prefers-color-scheme: dark)', {
            color: V.primary106White,
          }),
          $('@container (width < 40rem)', {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }),
        ],
      })}
    >
      <AlbertsonsLogo />
      <div>
        The Unified Design System is a collection of reusable components, guidelines, and tools used
        to create a consistent and cohesive user interface across Merchandising Systems.
      </div>
    </div>
  );
};
