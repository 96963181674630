import { PropsProps } from "./Props.types";
import PropsTable from "../../../../component/propsTable";

export default function Props({ data }: PropsProps) {
  return (
    <main className="mt-12 mb-20 lg:max-w-[840px]">
      <PropsTable title={data.title} url={data.url} />
    </main>
  );
}
