import {
  ComponentDetailsTabs,
  FoundationsColorTabs,
  FoundationsIconographyTabs,
  FoundationsTypographyTabs,
  StatusTrackerTabs,
} from "./utils.types";

export const COMPONENT_DETAILS_TABS: ComponentDetailsTabs = {
  overview: { name: "Overview", url: "overview" },
  specs: { name: "Specs", url: "specs" },
  guidelines: { name: "Guidelines", url: "guidelines" },
  playground: { name: "Playground", url: "playground" },
  props: { name: "Props", url: "props" },
};

export const FOUNDATIONS_COLOR_TABS: FoundationsColorTabs = {
  overview: { name: "Overview", url: "overview" },
  extended_palette: { name: "Extended Palette", url: "extended-palette" },
  guidelines: { name: "Guidelines", url: "guidelines" },
  accessibility: { name: "Accessibility", url: "accessibility" },
};

export const FOUNDATIONS_ICONOGRAPHY_TABS: FoundationsIconographyTabs = {
  overview: { name: "Overview", url: "overview" },
  guidelines: { name: "Guidelines", url: "guidelines" },
  accessibility: { name: "Accessibility", url: "accessibility" },
};

export const FOUNDATIONS_TYPOGRAPHY_TABS: FoundationsTypographyTabs = {
  overview: { name: "Overview", url: "overview" },
  guidelines: { name: "Guidelines", url: "guidelines" },
  accessibility: { name: "Accessibility", url: "accessibility" },
};
export const STATUS_TRACKER_TABS: StatusTrackerTabs = {
  v1: { name: "v1.0", url: "v1" },
  // v2: { name: "v2.0", url: "v2" },
};
