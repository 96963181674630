/* @prettier */

import { useRef } from 'react';
import { Footer } from '../../components/footer';
import { HomeCardContainer } from '../../components/home-card-container';
import { HomeCardCtaLayout } from '../../components/home-card-cta-layout';
import { HomeCardGraphicContainer } from '../../components/home-card-graphic-container';
import { HomeCardLink } from '../../components/home-card-link';
import { HomeCardPadding } from '../../components/home-card-padding';
import { HomeNumber } from '../../components/home-number';
import { Theme } from '../../context/ThemeProvider';
import { css } from '../../css';
import { useNavigation } from '../../hooks/useNavigation';
import useTheme from '../../hooks/useTheme';
import ComponentsCard from './component/ComponentsCard';
import FoundationsCard from './component/FoundationsCard';
import HeroCard from './component/heroCard/HeroCard';
import TokenssCard from './component/TokensCard';
import releaseNotesGraphic from './releaseNotes.svg';
import roadmapGraphic from './roadmap.svg';
import whyUdsGraphic from './whyUds.svg';

interface LandingPageProps {
  logOutUser?: () => Promise<void>;
}

export default function LandingPagev({ logOutUser }: LandingPageProps) {
  const { navigateToURL } = useNavigation();
  const [theme, setTheme] = useTheme();

  const sectionRefs = useRef<
    Record<'Foundations' | 'Components' | 'Design tokens', Element | null>
  >({
    Foundations: null,
    Components: null,
    'Design tokens': null,
  });
  return (
    <div
      style={{
        containerType: 'inline-size',
        width: '100%',
        maxWidth: '156.25rem',
        margin: '0 auto',
      }}
    >
      <main
        data-testid='landing-container'
        className='text-left'
        style={css({
          display: 'grid',
          gridTemplateAreas: "'a' 'b' 'c' 'd' 'e' 'f' 'g' 'h' 'i' 'j' 'k'",
          gap: '1rem',
          margin: '1rem',
          on: $ => [
            $('@container (width >= 32rem)', {
              gap: '1.5rem',
              margin: '1.5rem',
            }),
            $('@container (width >= 44rem)', {
              gap: '1.875rem',
              margin: '1.875rem',
            }),
            $('@container (width >= 80rem)', {
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateAreas: "'a a a' 'b b b' 'c c c' 'd d d' 'e f g' 'h i j' 'k k k'",
            }),
          ],
        })}
      >
        <div style={{ gridArea: 'a' }}>
          <HeroCard
            selectoptions={Object.values(Theme)}
            handleSelectChange={setTheme}
            currentValue={theme}
            onExploreSubmit={section => {
              sectionRefs.current[section]?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
        <div style={{ gridArea: 'b', position: 'relative' }}>
          <div
            style={{ position: 'absolute', top: -100 }}
            ref={x => {
              sectionRefs.current['Foundations'] = x;
            }}
          />
          <FoundationsCard currentValue={theme} />
        </div>
        <div style={{ gridArea: 'c', position: 'relative' }}>
          <div
            style={{ position: 'absolute', top: -100 }}
            ref={x => {
              sectionRefs.current['Components'] = x;
            }}
          />
          <ComponentsCard currentValue={theme} />
        </div>
        <div style={{ gridArea: 'd', position: 'relative' }}>
          <div
            style={{ position: 'absolute', top: -100 }}
            ref={x => {
              sectionRefs.current['Design tokens'] = x;
            }}
          />
          <TokenssCard currentValue={theme} />
        </div>
        {[
          {
            gridArea: 'e',
            stat: 45,
            label: 'components',
          },
          {
            gridArea: 'f',
            stat: 10000,
            label: 'downloads',
          },
          {
            gridArea: 'g',
            stat: 50,
            label: 'users',
          },
        ].map(({ gridArea, stat, label }) => (
          <HomeCardContainer key={gridArea}>
            <HomeCardPadding>
              <HomeNumber label={label} value={stat} />
            </HomeCardPadding>
          </HomeCardContainer>
        ))}
        {[
          {
            gridArea: 'h',
            graphic: { src: whyUdsGraphic, alt: 'People designing and building things in a room' },
            link: {
              href: '/resources',
              title: 'Why UDS?',
              description: 'How UDS helps processes and products',
            },
          },
          {
            gridArea: 'i',
            graphic: { src: releaseNotesGraphic, alt: 'Person sitting on top of a flying rocket' },
            link: {
              href: '/development/release-notes',
              title: 'Release notes',
              description: 'Announcements about the latest releases of UDS',
            },
          },
          {
            gridArea: 'j',
            graphic: { src: roadmapGraphic, alt: 'Person following signs along a path' },
            link: {
              href: '/resources/roadmap',
              title: 'Roadmap',
              description: "What's in progress and what's coming up next",
            },
          },
        ].map(({ gridArea, graphic, link }) => (
          <div key={gridArea} style={{ gridArea, display: 'contents' }}>
            <HomeCardContainer>
              <HomeCardPadding>
                <HomeCardCtaLayout
                  graphic={
                    <HomeCardGraphicContainer>
                      <img
                        src={graphic.src}
                        alt={graphic.alt}
                        style={css({
                          width: '100%',
                          height: '15.25rem',
                          objectFit: 'cover',
                          on: $ => [
                            $('@container (width >= 32rem)', {
                              height: '31.25rem',
                            }),
                          ],
                        })}
                      />
                    </HomeCardGraphicContainer>
                  }
                  link={
                    <HomeCardLink
                      href={link.href}
                      heading={link.title}
                      description={link.description}
                      onClick={e => {
                        e.preventDefault();
                        navigateToURL(link.href);
                      }}
                    />
                  }
                />
              </HomeCardPadding>
            </HomeCardContainer>
          </div>
        ))}
        <div style={{ gridArea: 'k' }}>
          <HomeCardContainer>
            <HomeCardPadding>
              <Footer onNavigate={navigateToURL} onLogout={logOutUser} />
            </HomeCardPadding>
          </HomeCardContainer>
        </div>
      </main>
    </div>
  );
}
