import { useRef, useEffect, useState } from "react";
import { PlaygroundProps } from "./Playground.types";

export default function Playground(props: PlaygroundProps) {
  const [loaded, setLoaded] = useState(false);

  const refer = useRef<HTMLIFrameElement>(null);
  const getIframe = () => {
    const iframeDoc =
      refer?.current?.contentDocument ||
      refer?.current?.contentWindow?.document;
    return iframeDoc;
  };
  const configureIframe = () => {
    const iframeDocument = getIframe();
    const styleElement = document.createElement("style");
    styleElement.textContent = ".sto-10ro1m { top: 0px !important; }";
    const styleElementOne = document.createElement("style");
    styleElementOne.textContent = ".sto-6v9ktq {display: none !important;}";
    const styleElementTwo = document.createElement("style");
    styleElementTwo.textContent = ".sto-o5aav8 {display: none !important;}";
    const styleElementThree = document.createElement("style");
    styleElementThree.textContent =
      ".sto-5grmpm {background: #F7F8FA !important;}";
    iframeDocument?.head.appendChild(styleElement);
    iframeDocument?.head.appendChild(styleElementOne);
    iframeDocument?.head.appendChild(styleElementTwo);
    iframeDocument?.head.appendChild(styleElementThree);
    setLoaded(!loaded);
  };
  const onLoad = () => {
    configureIframe();
  };
  const baseUrl = "/meui-doc/";
  const windowWidth = useRef(window.innerWidth);
  useEffect(() => {
    setLoaded(false);
    function handleResize() {
      if (window.innerWidth !== windowWidth.current) {
        window.location.reload();
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="">
      <div className="flex flex-col items-start mb-6 z-[201]">
        <h3 className=" text-[28px] leading-9  font-normal  z-[201]">
          {props.title}
        </h3>
        <p className=" text-base  font-normal  z-[201]">
          {props?.optionalCopy}
        </p>
      </div>
      <div className=" h-screen z-[100] block bg-white w-full">
        {/* <p className="z-[2000] relative bg-white h-[50px]  ml-[1px] -bottom-[1px] w-[calc(99%)]"></p> */}
        <iframe
          onLoad={onLoad}
          ref={refer}
          src={baseUrl + props.url}
          title="embedded-story"
          width={"100%"}
          className="relative z-0  h-screen bottom-5"
        ></iframe>
      </div>
    </section>
  );
}
