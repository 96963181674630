import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";

export enum Theme { light = 'light', dark = 'dark' }

export type ThemeProviderProps = {
  theme?: Theme; // Default 'light'
  children?: ReactNode;
};

export const ThemeContext = React.createContext<[Theme, Dispatch<SetStateAction<Theme>>]>([Theme.light, () => {}]);

const ThemeProvider = (props: ThemeProviderProps) => {
  const themePreference = window.localStorage.getItem("theme") as Theme;
  const [theme, setTheme] = useState<Theme>(props.theme || themePreference || Theme.light);

  useEffect(() => {
    const root = document.querySelector('html');
    root?.setAttribute('data-theme', theme);
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>{ props.children }</ThemeContext.Provider>
  );
};

export default ThemeProvider;