/** @prettier */

import { Fragment } from 'react';
import { css } from '../../css';
import { FooterBottomRow } from '../footer-bottom-row';
import { FooterLeft } from '../footer-left';
import { FooterLink } from '../footer-link';
import { FooterList } from '../footer-list';

const lists: { label: string; items: { label: string; href: string; target?: '_blank' }[] }[] = [
  {
    label: 'Category',
    items: [
      {
        label: 'Foundations',
        href: '/foundations',
      },
      {
        label: 'Design',
        href: '/design',
      },
      {
        label: 'Development',
        href: '/development',
      },
      {
        label: 'Components',
        href: '/components',
      },
      {
        label: 'Resources',
        href: '/resources',
      },
    ],
  },
  {
    label: 'Quick links',
    items: [
      {
        label: 'Figma',
        href: 'https://www.figma.com/file/YeI9btGRRS1KcrjTlHBppS/Unified-Design-System-Library-(UDS)?type=design&node-id=424-20656&mode=design&t=JNeqj2kiEBnux05V-0',
        target: '_blank',
      },
      {
        label: 'GitHub',
        href: 'https://github.albertsons.com/albertsons/MEUI-UDS',
        target: '_blank',
      },
      {
        label: 'Storybook',
        href: '/meui-doc',
        target: '_blank',
      },
    ],
  },
];

const legal = [
  { label: 'Privacy', href: '/privacy' },
  { label: 'Terms of Use', href: '/terms' },
  { label: 'Accessibility', href: '/accessibility' },
  { label: 'Contact Us', href: '/contact' },
];

export function Footer({
  onNavigate: navigateToURL = () => {},
  onLogout: logOut = () => {},
}: {
  onNavigate?: (url: string) => void;
  onLogout?: () => void;
}) {
  return (
    <footer style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1.5rem',
          containerType: 'inline-size',
        }}
      >
        <FooterLeft />
        <div style={{ flexBasis: 'calc((40rem - 100%) * 999)', flexGrow: 999 }} />
        {lists.map(({ label, items }) => (
          <Fragment key={label}>
            <section
              style={css({
                on: $ => [
                  $('@container (width < 40rem)', {
                    textAlign: 'center',
                  }),
                ],
              })}
            >
              <FooterList heading={<h1 style={{ all: 'unset' }}>{label}</h1>}>
                <ul style={{ display: 'contents' }}>
                  {items.map(({ label, href, target }) => (
                    <li key={href} style={{ all: 'unset' }}>
                      <FooterLink>
                        <a
                          href={href}
                          target={target}
                          onClick={e => {
                            if (target !== '_blank') {
                              e.preventDefault();
                              navigateToURL(e.currentTarget.getAttribute('href')!);
                            }
                          }}
                          style={{ all: 'unset' }}
                        >
                          {label}
                        </a>
                        {target === '_blank' ? (
                          <svg
                            viewBox='0 0 16 16'
                            fill='none'
                            style={{ minWidth: '1rem', maxWidth: '1rem', aspectRatio: 1 }}
                          >
                            <path
                              d='M11.5554 8.59255V12.1481C11.5554 12.4624 11.4305 12.7639 11.2083 12.9862C10.986 13.2084 10.6845 13.3333 10.3702 13.3333H3.85169C3.53736 13.3333 3.2359 13.2084 3.01364 12.9862C2.79137 12.7639 2.6665 12.4624 2.6665 12.1481V5.62959C2.6665 5.31526 2.79137 5.0138 3.01364 4.79154C3.2359 4.56927 3.53736 4.4444 3.85169 4.4444H7.40724M9.77761 2.66663H13.3332M13.3332 2.66663V6.22218M13.3332 2.66663L6.81465 9.18514'
                              stroke='currentColor'
                              strokeWidth='1.25'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        ) : undefined}
                      </FooterLink>
                    </li>
                  ))}
                </ul>
              </FooterList>
            </section>
            <div style={{ flexBasis: 'calc((40rem - 100%) * 999)', flexGrow: 200 }} />
          </Fragment>
        ))}
      </div>
      <FooterBottomRow
        links={
          <>
            {legal.map(({ label, href }) => (
              <FooterLink key={href}>
                <a
                  style={{ all: 'unset' }}
                  href={href}
                  onClick={e => {
                    e.preventDefault();
                    navigateToURL(e.currentTarget.getAttribute('href')!);
                  }}
                >
                  {label}
                </a>
              </FooterLink>
            ))}
            <FooterLink>
              <button style={{ all: 'unset' }} onClick={logOut}>
                Log Out
              </button>
            </FooterLink>
          </>
        }
      />
    </footer>
  );
}
