import React from "react";
import { CodeComponentProps } from "./CodeComponent.types";
import LiveCodeEditor from "./liveCodeEditor";
import clsx from "clsx";

export default function CodeComponent(props: CodeComponentProps) {
  return (
    <section className={clsx(props.className, "text-left w-full")}>
      {(props.title || props.optionalCopy) && (
        <div className="flex flex-col items-start mb-6">
          <h3 className=" text-[28px] leading-9  font-normal ">
            {props.title}
          </h3>
          {typeof props?.optionalCopy === "string" ? (
            <p className=" text-base  font-normal ">
              {props?.optionalCopy}
            </p>
          ) : (
            props?.optionalCopy && props?.optionalCopy()
          )}
        </div>
      )}
      {props.editors?.map((editor, index) => {
        const indexKey = index;
        return (
          <section key={indexKey} className="mb-14 w-full">
            <h5 className="text-xl font-extrabold font-sans  mb-1 max-w-[600px]">
              {editor.title}
            </h5>
            <p className="text-base font-normal  mb-10 max-w-[600px] leading-7">
              {typeof editor.optionalCopy === "string" ? (
                <p className="mb-1 flex justify-start text-base text-left">
                  {editor.optionalCopy}
                </p>
              ) : (
                editor.optionalCopy && editor.optionalCopy()
              )}
            </p>
            {editor.customCodeEditor ? (
              editor.customCodeEditor
            ) : (
              <div className="w-full">
                {
                  <LiveCodeEditor
                    className={editor.className}
                    scope={editor.scope}
                    code={editor.code}
                    noInline={editor.noInline}
                  />
                }
              </div>
            )}
          </section>
        );
      })}
    </section>
  );
}
