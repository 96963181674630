import React from "react";
import GenericCard from "./genericCard/GenericCard";

export default function ComponentsCard(props: { currentValue?: string }) {
  return (
    <GenericCard
      reverseColumns
      currentValue={props.currentValue}
      bgImage={"/componentAssets/component.svg"}
      navigateTo={"/components"}
      linkTitle={"See More"}
      title={"Components"}
      description={`The component library meets a specific interaction or UI need, and
            has been specifically created to work together to create patterns
            and intuitive user experiences.`}
    />
  );
}
