/* @prettier */

import { HomeCardContainer } from '../../../../components/home-card-container';
import { HomeCardContent } from '../../../../components/home-card-content';
import { HomeCardCtaLayout } from '../../../../components/home-card-cta-layout';
import { HomeCardGraphicContainer } from '../../../../components/home-card-graphic-container';
import { HomeCardLink } from '../../../../components/home-card-link';
import { HomeCardPadding } from '../../../../components/home-card-padding';
import { HomeCardText } from '../../../../components/home-card-text';
import { css } from '../../../../css';
import { useNavigation } from '../../../../hooks/useNavigation';

interface GenericCardProps {
  currentValue?: string;
  bgImage: string;
  navigateTo: string;
  linkTitle: string;
  title: string;
  description: string;
  reverseColumns?: boolean;
}

export default function GenericCard(props: GenericCardProps) {
  const { navigateToURL } = useNavigation();

  return (
    <section>
      <HomeCardContainer>
        <HomeCardPadding>
          <HomeCardContent reverseColumns={props.reverseColumns}>
            <HomeCardCtaLayout
              graphic={
                <HomeCardGraphicContainer>
                  <img
                    src={props.bgImage}
                    alt={props.linkTitle}
                    style={css({
                      objectFit: 'cover',
                      width: '100%',
                      height: '14.75rem',
                      on: $ => [
                        $('@container (width >= 32rem)', {
                          height: '18.75rem',
                        }),
                        $('@container (width >= 44rem)', {
                          height: '25rem',
                        }),
                      ],
                    })}
                  />
                </HomeCardGraphicContainer>
              }
              link={
                <HomeCardLink
                  href={props.navigateTo}
                  onClick={e => {
                    e.preventDefault();
                    navigateToURL(props.navigateTo);
                  }}
                  heading={props.title}
                />
              }
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                maxWidth: '50rem',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <HomeCardText variant='heading2' render='h1'>
                {props.title}
              </HomeCardText>
              <HomeCardText variant='paragraph' render='p'>
                {props.description}
              </HomeCardText>
            </div>
          </HomeCardContent>
        </HomeCardPadding>
      </HomeCardContainer>
    </section>
  );
}
