import { AboutProps } from "./About.types";

const About = ({ title, Content }: AboutProps) => {
  return (
    <div className="text-left">
      <h2 className="text-[28px]  mb-1">{title}</h2>
      <div className="text-base text-[2B303C] max-w-[600px]">{Content()}</div>
    </div>
  );
};

export default About;
