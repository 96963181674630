/* @prettier */

import colors from './colors.json';

export const extended507LightBlue200 = 'var(--Extended-507-Light-Blue-200, #b9e6fe)';
export const extended507LightBlue400 = 'var(--Extended-507-Light-Blue-400, #36bffa)';
export const extended508BrandBlue300 = 'var(--Extended-508-Brand-Blue-300, #9ecbf5)';
export const extended508BrandBlue500 = 'var(--Extended-508-Brand-Blue-500, #1b6ebb)';
export const extended508BrandBlue700 = 'var(--Extended-508-Brand-Blue-700, #134d84)';
export const extended513NeutralGray900 = 'var(--Extended-513-Neutral-Gray-900, #111927)';
export const bigCornerRadius = 'var(--Big-Corner-Radius, 1.25rem)';
export const functional404DisabledObject = 'var(--Functional-404-Disabled-Object, #b9c0d4)';
export const primary101Brand = 'var(--Primary-101-Brand, #1b6ebb)';
export const primary102BrandDark = 'var(--Primary-102-Brand-Dark, #0d2d49)';
export const primary104Brand = 'var(--Primary-104-Brand-Medium, #124c81)';
export const primary106White = 'var(--Primary-106-White, #fff)';
export const primary107BrandUltralight = 'var(--Primary-107-Brand-Ultralight, #7AB9F4)';
export const secondary201DarkText = 'var(--Secondary-201-Dark-Text, #2b303c)';
export const secondary202LightText = 'var(--Secondary-202-Light-Text, #5a697b)';
export const secondary204Gray = 'var(--Secondary-204-Gray, #c8daeb)';
export const secondary205Gray = 'var(--Secondary-205-Gray, #edeeef)';
export const secondary206Gray = 'var(--Secondary-206-Gray, #f1f4f9)';
export const smallCornerRadius = 'var(--Small-Corner-Radius, 0.5rem)';
export const tertiary301Blue = 'var(--Tertiary-301-Blue, #033b69)';
export const tertiary304Blue = 'var(--Tertiary-304-Blue, #93cbfe)';
export const tertiary305Blue = 'var(--Tertiary-305-Blue, #bcdffd)';
export const tertiary306Blue = 'var(--Tertiary-306-Blue, #e0edfb)';
export const tertiary307Blue = 'var(--Tertiary-307-Blue, #ebf3fa)';

// Not defined in Figma
export const sansSerif = 'Nunito Sans';
export const backdrop = '#0000004D';

// Color Constants
export const success = colors['success'];
export const warning = colors['warning'];
export const error = colors['error'];
export const disabled = colors['disabled'];
export const disabledText = colors['disabled-text'];
export const lightText = colors['light-text'];
export const darkText = colors['dark-text'];
export const brand = colors['brand'];
export const brandUltralight = colors['brand-ultralight'];
export const brandLight = colors['brand-light'];
export const brandMedium = colors['brand-medium'];
export const brandDark = colors['brand-dark'];
export const gray203 = colors['gray-203'];
export const gray204 = colors['gray-204'];
export const gray205 = colors['gray-205'];
export const gray206 = colors['gray-206'];
export const gray207 = colors['gray-207'];
export const gray208 = colors['gray-208'];
export const blue301 = colors['blue-301'];
export const blue302 = colors['blue-302'];
export const blue303 = colors['blue-303'];
export const blue304 = colors['blue-304'];
export const blue305 = colors['blue-305'];
export const blue306 = colors['blue-306'];
export const blue307 = colors['blue-307'];
export const blue308 = colors['blue-308'];
export const blue309 = colors['blue-309'];
export const compliantGreen = colors['compliant-green'];
export const compliantOrange = colors['compliant-orange'];
export const compliantRed = colors['compliant-red'];
export const red50 = colors['red-50'];
export const red100 = colors['red-100'];
export const red200 = colors['red-200'];
export const red300 = colors['red-300'];
export const red400 = colors['red-400'];
export const red500 = colors['red-500'];
export const red700 = colors['red-700'];
export const red900 = colors['red-900'];
export const orange50 = colors['orange-50'];
export const orange100 = colors['orange-100'];
export const orange200 = colors['orange-200'];
export const orange300 = colors['orange-300'];
export const orange400 = colors['orange-400'];
export const orange500 = colors['orange-500'];
export const orange700 = colors['orange-700'];
export const orange900 = colors['orange-900'];
export const honey50 = colors['honey-50'];
export const honey100 = colors['honey-100'];
export const honey200 = colors['honey-200'];
export const honey300 = colors['honey-300'];
export const honey400 = colors['honey-400'];
export const honey500 = colors['honey-500'];
export const honey700 = colors['honey-700'];
export const honey900 = colors['honey-900'];
export const lime50 = colors['lime-50'];
export const lime100 = colors['lime-100'];
export const lime200 = colors['lime-200'];
export const lime300 = colors['lime-300'];
export const lime400 = colors['lime-400'];
export const lime500 = colors['lime-500'];
export const lime700 = colors['lime-700'];
export const lime900 = colors['lime-900'];
export const green50 = colors['green-50'];
export const green100 = colors['green-100'];
export const green200 = colors['green-200'];
export const green300 = colors['green-300'];
export const green400 = colors['green-400'];
export const green500 = colors['green-500'];
export const green700 = colors['green-700'];
export const green900 = colors['green-900'];
export const teal50 = colors['teal-50'];
export const teal100 = colors['teal-100'];
export const teal200 = colors['teal-200'];
export const teal300 = colors['teal-300'];
export const teal400 = colors['teal-400'];
export const teal500 = colors['teal-500'];
export const teal700 = colors['teal-700'];
export const teal900 = colors['teal-900'];
export const blueLight50 = colors['blue-light-50'];
export const blueLight100 = colors['blue-light-100'];
export const blueLight200 = colors['blue-light-200'];
export const blueLight300 = colors['blue-light-300'];
export const blueLight400 = colors['blue-light-400'];
export const blueLight500 = colors['blue-light-500'];
export const blueLight700 = colors['blue-light-700'];
export const blueLight900 = colors['blue-light-900'];
export const blueBrand50 = colors['blue-brand-50'];
export const blueBrand100 = colors['blue-brand-100'];
export const blueBrand200 = colors['blue-brand-200'];
export const blueBrand300 = colors['blue-brand-300'];
export const blueBrand400 = colors['blue-brand-400'];
export const blueBrand500 = colors['blue-brand-500'];
export const blueBrand700 = colors['blue-brand-700'];
export const blueBrand900 = colors['blue-brand-900'];
export const indigo50 = colors['indigo-50'];
export const indigo100 = colors['indigo-100'];
export const indigo200 = colors['indigo-200'];
export const indigo300 = colors['indigo-300'];
export const indigo400 = colors['indigo-400'];
export const indigo500 = colors['indigo-500'];
export const indigo700 = colors['indigo-700'];
export const indigo900 = colors['indigo-900'];
export const purple50 = colors['purple-50'];
export const purple100 = colors['purple-100'];
export const purple200 = colors['purple-200'];
export const purple300 = colors['purple-300'];
export const purple400 = colors['purple-400'];
export const purple500 = colors['purple-500'];
export const purple700 = colors['purple-700'];
export const purple900 = colors['purple-900'];
export const magenta50 = colors['magenta-50'];
export const magenta100 = colors['magenta-100'];
export const magenta200 = colors['magenta-200'];
export const magenta300 = colors['magenta-300'];
export const magenta400 = colors['magenta-400'];
export const magenta500 = colors['magenta-500'];
export const magenta700 = colors['magenta-700'];
export const magenta900 = colors['magenta-900'];
export const grayCool50 = colors['gray-cool-50'];
export const grayCool100 = colors['gray-cool-100'];
export const grayCool200 = colors['gray-cool-200'];
export const grayCool300 = colors['gray-cool-300'];
export const grayCool400 = colors['gray-cool-400'];
export const grayCool500 = colors['gray-cool-500'];
export const grayCool700 = colors['gray-cool-700'];
export const grayCool900 = colors['gray-cool-900'];
export const grayNeutral50 = colors['gray-neutral-50'];
export const grayNeutral100 = colors['gray-neutral-100'];
export const grayNeutral150 = colors['gray-neutral-150'];
export const grayNeutral200 = colors['gray-neutral-200'];
export const grayNeutral300 = colors['gray-neutral-300'];
export const grayNeutral400 = colors['gray-neutral-400'];
export const grayNeutral500 = colors['gray-neutral-500'];
export const grayNeutral700 = colors['gray-neutral-700'];
export const grayNeutral900 = colors['gray-neutral-900'];
