/** @prettier */

import { FC, ReactNode, useEffect, useState } from 'react';
import * as V from '../../variables';
import { HomeCardText as Text } from '../home-card-text';

const initial = 0;

const formatNumber = (number: number): string => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0+$/, '') + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0+$/, '') + 'K';
  } else {
    return Math.round(number).toString();
  }
};

function visible(element: Element) {
  const box = element.getBoundingClientRect();
  const viewport = window;
  return box.top < viewport.innerHeight && box.bottom >= 0;
}

export const HomeNumber: FC<{ label: ReactNode; value: number }> = function HomeNumber({
  label,
  value,
}) {
  const [box, setBox] = useState<HTMLDivElement | null>(null);
  const [valueWrap, setValueWrap] = useState<HTMLSpanElement | null>(null);
  useEffect(() => {
    if (box && valueWrap) {
      let stop: (() => void) | undefined = undefined;

      const listener = () => {
        if (visible(box) && !stop) {
          stop = animate(progress => {
            valueWrap.textContent = formatNumber(value * progress);
          }, 500);
        } else if (!visible(box) && stop) {
          stop();
          stop = undefined;
          valueWrap.textContent = formatNumber(initial);
        }
      };

      window.addEventListener('scroll', listener);

      listener();

      return () => {
        window.removeEventListener('scroll', listener);
        valueWrap.textContent = formatNumber(initial);
      };
    }

    function animate(f: (progress: number) => void, duration: number) {
      let initialTimestamp = 0,
        raf = 0;
      (function iterate() {
        raf = requestAnimationFrame(function step(timestamp) {
          if (!initialTimestamp) {
            initialTimestamp = timestamp;
          }
          const progress = Math.min((timestamp - initialTimestamp) / duration, 1);
          f(progress);
          if (progress < 1) {
            iterate();
          }
        });
      })();
      return function stop() {
        if (raf) {
          cancelAnimationFrame(raf);
        }
      };
    }
  }, [box, valueWrap, value]);

  return (
    <div
      ref={setBox}
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: V.smallCornerRadius,
        textAlign: 'center',
      }}
    >
      <Text variant='label' render='dt'>
        {label}
      </Text>
      <Text variant='number' render='dd'>
        <span ref={setValueWrap}>{initial}</span>
      </Text>
    </div>
  );
};
