/* @prettier */

import { ComponentProps, createElement, CSSProperties, ReactElement, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

export function HomeCardText<
  Render extends
    | ((style?: { style?: CSSProperties }) => ReactElement)
    | keyof HTMLElementTagNameMap,
>({
  render,
  variant = 'paragraph',
  ...restProps
}: {
  render?: Render;
  children: ReactNode;
  variant?: 'label' | 'number' | `heading${1 | 2}` | 'paragraph';
} & Omit<ComponentProps<Render>, 'style' | 'className'>) {
  const style = css({
    margin: 0,
    color:
      variant === 'paragraph'
        ? V.primary102BrandDark
        : variant === 'label'
          ? V.secondary202LightText
          : V.primary101Brand,
    fontFamily: V.sansSerif,
    fontWeight: { label: 800, number: 600, heading1: 700, heading2: 600, paragraph: 400 }[variant],
    letterSpacing: variant === 'label' ? '0.09375em' : 0,
    textTransform: variant === 'label' ? 'uppercase' : 'none',
    on: $ => [
      $(
        '@container (width < 32rem)',
        {
          label: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
          },
          number: {
            fontSize: '3.75rem',
            lineHeight: '4.5rem',
          },
          heading1: {
            fontSize: '1.5rem',
            lineHeight: '1.8rem',
          },
          heading2: {
            fontSize: '1.5rem',
            lineHeight: '1.8rem',
          },
          paragraph: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
        }[variant],
      ),
      $(
        '@container (width >= 32rem)',
        {
          label: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
          },
          number: {
            fontSize: '5rem',
            lineHeight: '6rem',
          },
          heading1: {
            fontSize: '1.75rem',
            lineHeight: '2.1rem',
          },
          heading2: {
            fontSize: '1.75rem',
            lineHeight: '2.1rem',
          },
          paragraph: {
            fontSize: '1.125rem',
            lineHeight: '1.575rem',
          },
        }[variant],
      ),
      $(
        '@container (width >= 44rem)',
        {
          label: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
          },
          number: {
            fontSize: '6.25rem',
            lineHeight: '7.5rem',
          },
          heading1: {
            fontSize: '2.5rem',
            lineHeight: '3rem',
          },
          heading2: {
            fontSize: '2rem',
            lineHeight: '2.4rem',
          },
          paragraph: {
            fontSize: '1.5rem',
            lineHeight: '2.1rem',
          },
        }[variant],
      ),
      $('@media (prefers-color-scheme: dark)', {
        color: variant === 'paragraph' ? '#fff' : V.tertiary305Blue,
      }),
    ],
  });
  return (
    <div style={{ containerType: 'inline-size' }}>
      {typeof render === 'function'
        ? render({ style })
        : createElement(render || 'div', { style, ...restProps })}
    </div>
  );
}
