import React, { createContext, useState, useContext, useMemo } from "react";
import { useLocation } from "react-router";

interface Item {
  name: string;
  num: number;
  color: string;
}

interface AppContextValues {
  mode: Item | undefined;
  items: Item[];
  handleChange: (val: Item) => void;
  isDarkMode: boolean;
}

const AppContext = createContext<AppContextValues | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within the AppContextProvider");
  }
  return context;
};

interface AppContextProviderProps {
  children: React.ReactNode; // Define the children prop here
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [mode, setMode] = useState<Item>();
  const handleChange = (val: Item) => {
    setMode(val);
  };
  const location = useLocation();
  const isDarkMode = location.pathname === "/" && mode?.num === 2;

  const contextValues = useMemo((): AppContextValues => {
    const items = [
      {
        name: "Light Mode",
        num: 1,
        color: "#FFFFFF",
      },
      {
        name: "Dark Mode",
        num: 2,
        color: "#000000",
      },
    ];

    return {
      mode,
      items,
      handleChange,
      isDarkMode,
    };
  }, [mode, isDarkMode]);

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
