import React, {
  Suspense,
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import SideBar from "../sidebar3/Sidebar";
import Header from "../header/Header";
import { Footer } from "../../components/footer";
import { navLinks } from "../header/utils/navligationLinkLists";
import splitLocationString from "../header/utils/getLocation";
import { useNavigation } from "../../hooks/useNavigation";
import { SearchProvider } from "../../providers";
import { css } from "../../css";
import * as V from "../../variables";

const LazyOutlet = React.lazy(() => import("./Outlet"));

interface RootProps {
  isOpen?: boolean;
  setOpen?: (_: boolean) => void;
  loginLoading?: boolean;
  logOutUser?: () => Promise<void>;
  setInLoginFlow: Dispatch<SetStateAction<boolean>>;
  inLoginFlow: boolean;
}

const Root = ({
  isOpen,
  setOpen,
  loginLoading,
  logOutUser,
  setInLoginFlow,
  inLoginFlow,
}: RootProps) => {
  const { getLocation } = useNavigation();
  const rootRef = useRef(null);

  const location = useLocation();
  const { accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { navigateToURL } = useNavigation();

  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      !isAuthenticated &&
      !loginLoading &&
      !inLoginFlow
    ) {
      setTimeout(() => {
        if (accounts.length === 0) {
          setInLoginFlow(true);
          loginUser();
        }
      }, 50);
    } else if (isAuthenticated && inLoginFlow) {
      setInLoginFlow(false);
    }
    // eslint-disable-next-line
  }, [inProgress]);

  const loginUser = async () => {
    try {
      const redirectAfterLogin = location.pathname + location.search || "";
      const response = await axios(
        `${window.location.origin}/uds-doc/auth/login${
          redirectAfterLogin ? `?redirectAfterLogin=${redirectAfterLogin}` : ""
        }`
      );
      window.open(response.data, "_self");
    } catch (error) {
      console.log("There was an error with login :", error);
    }
  };

  let isNotHomepage;
  switch (getLocation.pathname) {
    case "/":
      isNotHomepage = false;
      break;
    default:
      isNotHomepage = true;
      break;
  }

  useEffect(() => {
    const root = document.querySelector('html');
    root?.setAttribute('data-pathname', location.pathname);
  }, [location.pathname]);

  return (
    <>
      <UnauthenticatedTemplate>
        Checking Sign in Status...
      </UnauthenticatedTemplate>
      {loginLoading ? (
        <div className=" flex justify-center items-center h-screen">
          <div className=" animate-spin rounded-full border-t-4 border-[#00529F] h-20 w-20"></div>
        </div>
      ) : (
        <AuthenticatedTemplate>
          <SearchProvider>
          <div
            data-testid="root-container"
            ref={rootRef}
            tabIndex={-1}
            style={css({
              color: V.brandDark,
              background: clsx({
                "#EBF3FA": getLocation.pathname === "/",
                "#FFFFFF": getLocation.pathname !== "/",
              }),
              on: $ => [
                $("@media (prefers-color-scheme: dark)", { background: V.grayNeutral900 })
              ]
            })}
          >
            <Header
              isOpen={isOpen}
              setOpen={setOpen}
              currentPage={splitLocationString(getLocation.pathname)}
            />
            <div className="flex w-full pt-[80px]">
              {isNotHomepage && (
                <div
                  data-testid="sidebar-container"
                  className="hidden md:block md:fixed top-0 pt-[80px] h-[100vh] overflow-hidden"
                >
                  <SideBar sideBarLinks={navLinks} rootRef={rootRef} />
                </div>
              )}
              <main
                data-testid="rootContent"
                className={clsx("flex justify-center w-full", {
                  "md:ml-[248px]": isNotHomepage,
                })}
              >
                <div
                  data-testid="content-container"
                  className={`${
                    getLocation.pathname !== "/components"
                      ? "max-w-[25600px]"
                      : ""
                  } 
            flex flex-col justify-between w-full`}
                >
                  <Suspense
                    fallback={
                      <div className=" flex justify-center items-center h-screen">
                        <div className=" animate-spin rounded-full border-t-4 border-[#00529F] h-20 w-20"></div>
                      </div>
                    }
                  >
                    <LazyOutlet />
                  </Suspense>
                  <div
                    className={clsx(
                      'text-left',
                      `${getLocation.pathname !== "/" && "sm:mx-8 mt-8"}`,
                      `${getLocation.pathname === "/" ? "hidden" : "block"}`
                    )}
                  >
                    <Footer onNavigate={navigateToURL} onLogout={logOutUser} />
                  </div>
                </div>
              </main>
            </div>
          </div>
          </SearchProvider>
        </AuthenticatedTemplate>
      )}
    </>
  );
};

export default Root;
