import React, { useMemo } from "react";
import clsx from "clsx";
import { X as CloseIcon } from "lucide-react";
import Search from "@albertsons/uds/molecule/Search";
import SidebarMobile from "./sidebarMobile";
import SidebarDesktop from "./SidebarDesktop";
import splitLocationString from "../header/utils/getLocation";
import { SideBarProps } from "./Sidebar.types";
import { useNavigation } from "../../hooks/useNavigation";
import { useSearch } from "../../providers";

const SideBar: React.FC<SideBarProps> = ({
  sideBarLinks,
  className = "",
  showClose = false,
  closeClick = () => {},
  isMobile = false,
  isDark,
  rootRef,
}) => {
  const { navigateToURL, getLocation } = useNavigation();
  const { searchInput, setSearchInput } = useSearch();

  const activeTopLink = useMemo(() => {
    return sideBarLinks.filter(({ to, subMenu }) => {
      return to === `/${splitLocationString(getLocation.pathname)}` || subMenu?.some(x => x.to === getLocation.pathname);
    });
  }, [getLocation.pathname, sideBarLinks]);

  const onClick = (to: string): void => {
    navigateToURL(to);
    rootRef?.current.focus();
  };

  const itemsFound = useMemo(() => {
    const sideBar = isMobile ? sideBarLinks.map(sideBarLink => sideBarLink.subMenu).flat() : sideBarLinks.find((sidebarLink) => sidebarLink.to === activeTopLink[0].to)?.subMenu

    if (!sideBar) return false

    const match = sideBar?.find((submenu) => submenu && submenu.label.toLowerCase().includes(searchInput.toLowerCase()))
    const isComponentsPage = activeTopLink[0]?.to === '/components'
    const components = sideBar?.map((submenu) => submenu && submenu.children).flat()
    const componentMatch = components?.some((component) => component?.label && component.label.toLowerCase().includes(searchInput.toLowerCase()))

    if (isMobile) {
      return (match || componentMatch)
    }

    if (isComponentsPage) {
      return (componentMatch || match?.label.toLowerCase() === 'overview')
    }

    if (match) {
      return true
    }

    return false

  }, [searchInput, activeTopLink, sideBarLinks, isMobile]);

  return (
    <div
      className={clsx(
        "h-[calc(100dvh-80px)] max-h-[calc(100dvh-80px)] w-[248px] cursor-default flex flex-col break-words",
        className,
        { "pt-6": showClose },
        { "bg-[#2B303C]": isDark },
        { "bg-[#F7F8FA]": !isDark }
      )}
    >
      {showClose && (
        <div className="flex justify-end pb-3 mr-6">
          <button
          data-testid='close-button'
            onClick={(e) => {
              e.stopPropagation();
              closeClick();
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      <section
        className={clsx("text-[#2B303C] text-lg leading-6 w-auto text-left flex-1 flex flex-col min-h-0", {
          "pt-6": !showClose,
        })}
      >
        {!isMobile && (
          <h2 className="font-bold text-base mx-6 py-2">
            {activeTopLink.length > 0 ? activeTopLink[0].label : ""}
          </h2>
        )}
        <div className="mb-4">
          <Search noClear value={ searchInput } onChange={ (query) => setSearchInput(query) } placeholder="Search" className="w-[calc(100%_-_48px)] h-[42px] mx-6 mt-2 mb-6 [&>input]:placeholder-dark-text px-2 flex-shrink-0" />
          {(searchInput && !itemsFound) && <p className="text-base px-6 -mt-3.5 mb-4" >
            No results for
            {<span className="font-bold"> "{searchInput}"</span>}
            <br />Try another search term.
          </p>}
        </div>
        {isMobile ? (
          <div className="overflow-y-auto border-[#cedeed] border-t">
            <SidebarMobile
              navItems={sideBarLinks || []}
              onClick={onClick}
              isDark={isDark}
              activePage={activeTopLink[0]}
            />
          </div>
        ) : (
          <SidebarDesktop
            navItems={sideBarLinks || []}
            onClick={onClick}
            activePage={activeTopLink[0]}
          />
        )}
      </section>
    </div>
  );
};
export default SideBar;
