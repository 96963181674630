import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./configs/msal.config";
import App from "./App";
import "./tailwind.css";
import { MsalProvider } from "@azure/msal-react";
import ThemeProvider from './context/ThemeProvider';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider>
        <App msalInstance={msalInstance} />
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
