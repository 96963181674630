import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();
  console.error(error);

  return (
    <div
      data-testid="error-container"
      className="flex items-center justify-center h-screen"
    >
      <div id="error-page" className="text-center">
        <h1 className="text-4xl font-bold">Oops!</h1>
        <p className="text-2xl">Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{`Page ${error?.statusText || error?.message}`}</i>
        </p>
      </div>
    </div>
  );
}
