import React from "react";
import MeasurementCard from "./MeasurementCard";
import { MeasurementSectionProps } from "./Measurements.types";

const MeasurementSection: React.FC<MeasurementSectionProps> = ({
  data,
  title,
}) => {
  return (
    <section className="w-full">
      <h3 className="text-[28px]  leading-9 mb-10 text-left">
        {title}
      </h3>
      <section className={`grid w-full grid-cols-1 gap-4 sm:grid-cols-2`}>
        {data.map((measurement) => (
          <div
            className={`${
              measurement.isBig ? "sm:col-span-2" : "sm:col-span-1"
            }`}
            key={measurement.id}
            data-testid={measurement.isBig ? "big-card" : "small-card"}
          >
            <MeasurementCard
              imgURL={measurement.image}
              altText={measurement?.alt}
              className={measurement?.className}
            />
          </div>
        ))}
      </section>
    </section>
  );
};

export default MeasurementSection;
