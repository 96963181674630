import { useState, useEffect } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TabObject } from "../data/utils/utils.types";

export function useNavigation(tabs: TabObject[] = [{ name: "", url: "" }]) {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  const getActiveTab = () => {
    if(!location) {
      return false;
    }
    const splitArray = location?.search.split("&");
    const active = splitArray.find((el) => {
      return el.includes("tab=");
    });
    return active?.split("=")[1]?.toLowerCase();
  };

  const [activeTab, setActiveTab] = useState(getActiveTab() || tabs[0].url);

  useEffect(() => {
    setActiveTab(getActiveTab() || tabs[0].url);
    // eslint-disable-next-line
  }, [slug, tabs]);

  const navigateToURL = (to: string | null = null, active: string = "") => {
    if (to || active !== "") window.scrollTo({ top: 0, behavior: "smooth" });
    if (active !== "") {
      setActiveTab(active);
      navigate(
        {
          pathname: to === null ? location.pathname : to,
          search: createSearchParams({ tab: active }).toString(),
        },
        { state: { activeTab: active } }
      );
    } else {
      navigate({
        pathname: to === null ? location.pathname : to,
      });
    }
  };

  return {
    navigateToURL: navigateToURL,
    getTab: activeTab,
    getSlug: slug,
    getLocation: location,
  };
}
