import * as AnchorLink from '../../../data/componentData/AnchorLink/index';
import * as TabBar from '../../../data/componentData/TabBar';

/* Top Navigation - Header component */
const generateComponents = (components: any[]) => {
  return components.map((item: any[]) => {
    return {
      label: item[0],
      to: item[1],
      description: item[2],
      imageURL: item[3],
      verticalAlignment: item[4],
      horizontalAlignment: item[5],
      imageWidth: item[7],
    };
  });
};

// const templateChildren = [
//   [
//     "Email",
//     "/components/email",

//     "Email templates help developers quickly build consistent, easy to understand layouts.",
//     "/componentAssets/Email.svg",
//     "flex-end",
//     "center",
//     "242px",
//   ],
//   [
//     "Form",
//     "/components/form",

//     "Form templates include validation and layout examples to speed up development.",
//     "/componentAssets/Form.svg",
//     "center",
//     "center",
//     "242px",
//   ],
//   [
//     "Hero",
//     "/components/hero",

//     "The hero template exists on each internal page below the top navigation component.",
//     "/componentAssets/Hero.svg",
//     "flex-end",
//     "center",
//     "315px",
//   ],
//   [
//     "Main",
//     "/components/main",

//     "The main template provides a responsive structure with key layout elements.",
//     "/componentAssets/Main.svg",
//     "flex-end",
//     "center",
//     "242px",
//   ],
//   [
//     "Side Rail",
//     "/components/side-rail",

//     "Side rails contain actions, information, and progress information related to a form.",
//     "/componentAssets/SideRail.svg",
//     "center",
//     "center",
//     "196px",
//   ],
// ];
const navigationChildren = [
  [
    AnchorLink.name,
    "/components/anchorlink",
    AnchorLink.description,
    AnchorLink.illustration,
    "center",
    "center",
    "160px",
  ],
  [
    "Breadcrumb",
    "/components/breadcrumb",

    "Breadcrumbs show users where they are in a site's navigation hierarchy.",
    "/componentAssets/Breadcrumb.svg",
    "center",
    "center",
    "134px",
  ],
  [
    "Link",
    "/components/link",

    "Link is mainly used as navigational element and usually appear within or directly following a paragraph or sentence.",
    "/componentAssets/Link.svg",
    "center",
    "center",
    "64px",
  ],
  /*[
    "Pagination",
    "/components/pagination",
    
      "Pagination lets users to cycle through long sets of content across multiple pages.",
   "/componentAssets/Pagination.svg",
    "center",
     "center",
     "191px",
  ],
  [
    "Side Bar",
    "/components/sidebar",
    
      "Sidebar provides Albertsons applications with a consistent style of navigation on the left.",
   "/componentAssets/Sidebar.svg",
    "flex-end",
     "flex-end",
     "244px",
  ],*/
  [
    "Tab",
    "/components/tab",

    "Tabs make it easy to explore and switch between groups of content that are related.",
    "/componentAssets/Tab.svg",
    "center",
    "center",
    "49px",
  ],
  [
    TabBar.name,
    "/components/tabbar",
    TabBar.description,
    TabBar.illustration,
    "center",
    "center",
    "213px",
  ],
];

const layoutChildren = [
  /*[
    "Accordian",
   "/components/accordian",
      "Accordion conserves available page space by allowing users to show and hide sections.",
    "/componentAssets/Accordion.svg",
    "center",
     "center",
     "234px",
  ],*/
  [
    "Backdrop",
    "/components/backdrop",
    "Backdrop appears when a modal is opened, separating the background from the modal.",
    "/componentAssets/Backdrop.svg",
    "center",
    "center",
    "170px",
  ],
  [
    "Card",
    "/components/card",
    "A card is a container for content and actions related to a single subject.",
    "/componentAssets/Card.svg",
    "flex-end",
    "center",
    "180px",
  ],
  /*[
    "Card Stack",
   "/components/card-stack",
      "A card stack condenses and organizes multiple cards into a group that can expanded.",
    "/componentAssets/CardStack.svg",
    "flex-end",
     "center",
     "228px",
  ],
  [
    "Drawer",
   "/components/drawer",
      "Drawers can provide access to supplementary content that the user can focus on.",
    "/componentAssets/Drawer.svg",
    "flex-end",
     "flex-end",
     "180px",
  ],
  [
    "Icon",
   "/components/icon",
      "Icons are small symbols used to perform actions or display concise visual indicators.",
    "/componentAssets/Icons.svg",
    "center",
     "center",
     "112px",
  ],
  [
    "Image",
   "/components/image",
      "Image lets developers easily implement different types of images in multiple ways.",
    "/componentAssets/Image.svg",
    "center",
     "center",
     "166px",
  ],
  [
    "Logo",
   "/components/logo",
      "Logo allows developers to access necessary Albertsons company logos.",
    "/componentAssets/Logo.svg",
    "center",
     "center",
     "136px",
  ],*/
  [
    "Divider",
    "/components/divider",
    "Divider are used to visually separate content and help organize a page.",
    "/componentAssets/Divider.svg",
    "center",
    "center",
    "192px",
  ],
  [
    "Footer",
    "/components/footer",
    "Footer provides a standard look and feel for required footer information.",
    "/componentAssets/Footer.svg",
    "flex-end",
    "center",
    "315px",
  ],
  [
    "Header",
    "/components/header",
    "Header is used to provide consistency for general information across applications.",
    "/componentAssets/Header.svg",
    "flex-start",
    "center",
    "315px",
  ],
  [
    "Modal",
    "/components/modal",
    "A modal disrupts the user's flow and provides important prompts or information.",
    "/componentAssets/Modal.svg",
    "center",
    "center",
    "161px",
  ],
  [
    "Table",
    "/components/table",
    "Table is ideal for organizing and displaying dense data that needs to be compared.",
    "/componentAssets/Table.svg",
    "flex-end",
    "center",
    "241px",
  ],
];

const feedBackChildren = [
  [
    "Alert",
    "/components/alert",
    "Alert displays a short, important message that needs immediate attention from the user.",
    "/componentAssets/Alert.svg",
    "center",
    "center",
    "212px",
  ],
  [
    "Avatar",
    "/components/avatar",
    "Avatar represents a user and can show information such as their status and title.",
    "/componentAssets/Avatar.svg",
    "center",
    "center",
    "48px",
  ],
  [
    "Progress",
    "/components/progress",
    "Progress bar visualizes the loading state of an object or total progress in a stepped process.",
    "/componentAssets/Progress.svg",
    "center",
    "center",
    "192px",
  ],
  [
    "Skeleton Loader",
    "/components/skeletonloader",
    "Skeleton loader is used to show page or section of a page is still rendering.",
    "/componentAssets/SkeletonLoader.svg",
    "center",
    "center",
    "140px",
  ],
  [
    "Slider",
    "/components/slider",
    "Slider permits users to choose either a solitary numerical value or a span within the minimum and maximum value range.",
    "/componentAssets/SliderOne.svg",
    "center",
    "center",
    "212px",
  ],
  [
    "Spinner",
    "/components/spinner",
    "A spinner is a circular component that lets the user know something is loading.",
    "/componentAssets/Spinner.svg",
    "center",
    "center",
    "72px",
  ],
  /*[
   "Stepper",
   "/components/stepper",
      "Stepper lets users visualize their progress through a sequence of tasks.",
    "/componentAssets/Stepper.svg",
    "flex-end",
     "center",
     "180px",
  ],*/
  [
    "Tag",
    "/components/tag",
    "A tag is used to represent a certain state, status, or category of data.",
    "/componentAssets/Tag.svg",
    "center",
    "center",
    "112px",
  ],
  /*[
   "Timeline",
   "/components/timeline",
      "Timelines are used to display the history of a subject, such as a record of changes.",
    "/componentAssets/Timeline.svg",
    "flex-end",
     "center",
     "180px",
  ],*/
  [
    "Tooltip",
    "/components/tooltip",
    "A tooltip provides users with additional details about on-screen elements.",
    "/componentAssets/Tooltip.svg",
    "center",
    "center",
    "108px",
  ],
];
const actionsChildren = [
  /*[
   "Autocomplete",
   "/components/auto-complete",
      "Autocomplete is an input that provides suggestions to the user as they type.",
    "/componentAssets/Autocomplete.svg",
    "flex-end",
     "center",
     "212px",
  ],*/
  [
    "Button",
    "/components/button",
    "Buttons help users to quickly perform a single action, or see more actions they can take.",
    "/componentAssets/Button.svg",
    "center",
    "center",
    "212px",
  ],
  [
    "Checkbox",
    "/components/checkbox",
    "A checkbox lets users select one or more options from a list of choices.",
    "/componentAssets/Checkbox.svg",
    "center",
    "center",
    "160px",
  ],
  /*[
   "Date Picker",
   "/components/date-picker",
      "Date Picker lets users select a single date or a range of dates from an input or calendar.",
    "/componentAssets/DatePicker.svg",
    "center",
     "center",
     "262px",
  ],
  [
   "Draggable",
   "/components/draggable",
      "Draggable lets users rearrange a list of options in the order that they desire.",
    "/componentAssets/Draggable.svg",
    "center",
     "center",
     "20px",
  ],
  [
   "Dropdown",
   "/components/dropdown",
      "Dropdown allows other components to be used as an anchor for a menu of options.",
    "/componentAssets/Dropdown.svg",
    "center",
     "center",
     "212px",
  ],
  [
   "Input",
   "/components/input",
      "An input is used to let users enter and edit text in forms and other flows.",
    "/componentAssets/Input.svg",
    "center",
     "center",
     "170px",
  ],*/
  [
    "Icon Button",
    "/components/iconbutton",
    "Icon Button enables users to perform actions and make selections effortlessly with a single click or tap. Instead of relying on text, Icon Buttons employ icons to communicate the available actions on a screen.",
    "/componentAssets/IconButton.svg",
    "center",
    "center",
    "40px",
  ],
  [
    "Menu",
    "/components/menu",
    "Menu is a list of groupable actions inside of a container. It is toggled by the user and is most often used when space is limited.",
    "/componentAssets/Menu.svg",
    "center",
    "center",
    "141px",
  ],
  [
    "Radio",
    "/components/radio",
    "Radio lets users select a single option at a time from a list.",
    "/componentAssets/Radio.svg",
    "center",
    "center",
    "160px",
  ],
  /*[
   "Search",
   "/components/search",
      "Search is a styled input that is used to capture user search queries.",
    "/componentAssets/Search.svg",
    "center",
     "center",
     "212px",
  ],
  [
   "Select",
   "/components/select",
      "A select is an input type that lets users choose one or more options from a list.",
    "/componentAssets/Select.svg",
    "flex-end",
     "center",
     "212px",
  ],
  [
   "Text Area",
   "/components/text-area",
      "Text area is an input type used by the user to enter multiple lines of text.",
    "/componentAssets/TextArea.svg",
    "center",
     "center",
     "170px",
  ],*/
  [
    "Text Field",
    "/components/textfield",
    "Text Field is used to let users enter and edit text in forms and other flows.",
    "/componentAssets/Textfield.svg",
    "center",
    "center",
    "170px",
  ],
  [
    "Toggle",
    "/components/toggle",
    "Toggle lets users easily enable or disable something by switching between two states.",
    "/componentAssets/Toggle.svg",
    "center",
    "center",
    "57px",
  ],
];

const navLinks = [
  {
    label: "Foundations",
    to: "/foundations",
    subMenu: [
      {
        label: "Overview",
        to: "/foundations",
      },
      {
        label: "Color",
        to: "/foundations/color",
      },
      {
        label: "Typography",
        to: "/foundations/typography",
      },
      {
        label: "Iconography",
        to: "/foundations/iconography",
      },
      {
        label: "Design Tokens",
        to: "/foundations/design-tokens",
      },
    ],
  },
  {
    label: "Design",
    to: "/design",
    subMenu: [
      {
        label: "Overview",
        to: "/design",
      },
      {
        label: "Principles",
        to: "/design/principles",
      },
    ],
  },
  {
    label: "Development",
    to: "/development",
    subMenu: [
      {
        label: "Overview",
        to: "/development",
      },
      {
        label: "Release Notes",
        to: "/development/release-notes",
      },
    ],
  },
  {
    label: "Components",
    to: "/components",
    subMenu: [
      {
        label: "Overview",
        to: "/components",
      },
      {
        label: "Actions & Inputs",
        children: generateComponents(actionsChildren),
      },
      {
        label: "Feedback & Status",
        children: generateComponents(feedBackChildren),
      },
      {
        label: "Layout",
        children: generateComponents(layoutChildren),
      },
      {
        label: "Navigation",
        children: generateComponents(navigationChildren),
      },
      /*{
        label: "Templates",
        children: generateComponents(templateChildren),
      },*/
    ],
  },
  {
    label: "Resources",
    to: "/resources",
    subMenu: [
      {
        label: "Overview",
        to: "/resources",
      },
      {
        label: "Roadmap",
        to: "/resources/roadmap",
      },
      {
        label: "Status Tracker",
        to: "/resources/status-tracker",
      },
    ],
  },
  {
    label: "Contact & Support",
    to: "/contact",
    subMenu: [
      {
        label: "Privacy Policy",
        to: "/privacy",
      },
      {
        label: "Terms of Use",
        to: "/terms",
      },
      {
        label: "Accessibility",
        to: "/accessibility",
      },
      {
        label: "Contact Us",
        to: "/contact",
      },
    ],
  },
];

export { navLinks };
