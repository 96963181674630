import { useState } from "react";

interface TabProps {
  text: string;
  id?: number;
  selected?: boolean;
  onClick?: () => void;
  url?: string;
}

const Tab = ({
  text,
  id = 0,
  selected = false,
  onClick = () => {},
}: TabProps) => {
  const [over, setOver] = useState(false);

  const reg = `text-base text-center mx-3  pb-2 tracking-[0.125px] ${
    over || selected ? "opacity-0" : "opacity-1"
  } ${id === 0 ? "ml-0" : "ml-3"}`;
  const bold = `absolute text-base border-b-4 border-[#3997EF] text-center mx-3  whitespace-nowrap pb-[7px] tracking-[0.125px] ${
    over || selected ? "opacity-1" : "opacity-0"
  } ${id === 0 ? "ml-0" : "ml-3"}`;
  return (
    <button
      data-testid='TabButton'
      className="cursor-pointer mt-4"
      onClick={() => {
        onClick();
      }}
      onMouseOver={() => {
        setOver(true);
      }}
      onMouseOut={() => {
        setOver(false);
      }}
    >
      <div data-testid='tabBold' className={bold}>{text}</div>
      <div data-testid='tabReg' className={reg}>{text}</div>
    </button>
  );
};

export default Tab;
