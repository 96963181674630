import React, { useState } from "react";
import { LiveCodeEditorProps } from "./LiveCodeEditor.types";
import clsx from "clsx";
import { LivePreview, LiveError, LiveProvider, LiveEditor } from "react-live";
import { ChevronDown, ChevronUp } from "lucide-react";

export default function LiveCodeEditor(props: LiveCodeEditorProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleCollapse = () => {
    setIsExpanded((prevState) => !prevState);
  };
  return (
    <div className={clsx("live-code-editor w-full", props.className)}>
      <LiveProvider
        code={props.code}
        scope={props.scope}
        noInline={props.noInline}
        language="tsx"
        enableTypeScript
      >
        <div
          className={clsx(
            "preview p-10 bg-[#F7F8FA] flex justify-center items-center min-h-[160px] overflow-auto w-full"
          )}
        >
          <LivePreview
            Component={"div"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <LiveError />
        </div>
        <div>
          {isExpanded && (
            <div className="w-full">
              <LiveEditor />
            </div>
          )}
          <div
            className=" cursor-pointer p-4 flex justify-center items-center border border-[#C8DAEB] w-full"
            onClick={toggleCollapse}
            data-testid='btn-container'
          >
            {isExpanded ? (
              <p className="flex text-sm leading-6 font-normal  ">
                Collapse Code <ChevronUp aria-label="chevron-up"/>
              </p>
            ) : (
              <p className="flex text-sm leading-6 font-normal ">
                Expand Code <ChevronDown aria-label="chevron-down"/>
              </p>
            )}
          </div>
        </div>
      </LiveProvider>
    </div>
  );
}
