/* @prettier */

import { FC, ReactNode } from 'react';
import { css } from '../../css';
import * as V from '../../variables';

export const HomeCardContainer: FC<{ children?: ReactNode }> = function HomeCardContainer({
  children,
}) {
  return (
    <section
      style={css({
        display: 'grid',
        borderRadius: V.bigCornerRadius,
        background: '#fff',
        boxShadow: '0px 2px 3px 0px rgba(3, 59, 105, 0.10)',
        on: $ => [
          $('@media (prefers-color-scheme: dark)', {
            background: V.secondary201DarkText,
          }),
        ],
      })}
    >
      {children}
    </section>
  );
};
