/* @prettier */

import { Children, FC, ReactNode } from 'react';
import { css } from '../../css';

export const HomeCardContent: FC<{ children?: ReactNode; reverseColumns?: boolean }> =
  function HomeCardContent({ children, reverseColumns = false }) {
    return (
      <section style={{ containerName: 'group', containerType: 'inline-size' }}>
        <div
          style={css({
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2.5rem',
            columnGap: '2.75rem',
            on: ($, { and }) => [
              $(and('@container (width >= 32rem)', '@container (width < 44rem)'), {
                rowGap: '3rem',
              }),
              $('@container (width >= 80rem)', {
                columnGap: '3.125rem',
              }),
              $('@container (width >= 44rem)', {
                flexDirection: `row${reverseColumns ? '-reverse' : ''}`,
              }),
            ],
          })}
        >
          {Children.map(children, child => (
            <div style={{ flex: 1, display: 'grid', placeItems: 'center' }}>{child}</div>
          ))}
        </div>
      </section>
    );
  };
