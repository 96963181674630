/* @prettier */

import Button from '@albertsons/uds/molecule/Button';
import Checkbox from '@albertsons/uds/molecule/Checkbox';
import Select from '@albertsons/uds/molecule/Select';
import Toggle from '@albertsons/uds/molecule/Toggle';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useReducer, useState } from 'react';
import { HomeCardContainer } from '../../../../components/home-card-container';
import { HomeCardContent } from '../../../../components/home-card-content';
import { HomeCardGraphicContainer } from '../../../../components/home-card-graphic-container';
import { HomeCardPadding } from '../../../../components/home-card-padding';
import { HomeCardText } from '../../../../components/home-card-text';
import { Theme } from '../../../../context/ThemeProvider';
import { css } from '../../../../css';
import { useNavigation } from '../../../../hooks/useNavigation';
import { titleCase } from '../../../../util/string';
import * as V from '../../../../variables';
import backgroundLeft from './backgroundLeft.svg';
import backgroundRight from './backgroundRight.svg';
import mascot from './mascot.svg';

const exploreSections = {
  a: 'Foundations',
  b: 'Components',
  c: 'Design tokens',
} as const;

function exploreReducer(
  state: { [K in keyof typeof exploreSections]: boolean },
  action: Partial<{ [K in keyof typeof exploreSections]: boolean }>,
) {
  return { ...state, ...action };
}

function Explore(props: {
  onSubmit: (selection: (typeof exploreSections)[keyof typeof exploreSections]) => void;
}) {
  const [selection, setSelection] = useReducer(exploreReducer, { a: true, b: true, c: true });
  return (
    <form
      style={{
        display: 'inline-grid',
        gridTemplateAreas: '"a x" "b x" "c x"',
        gap: '1rem 2rem',
      }}
      onSubmit={e => {
        e.preventDefault();
        props.onSubmit(
          Object.entries(selection)
            .filter(([, value]) => value)
            .map(([key]) => exploreSections[key as keyof typeof exploreSections])[0] ||
            'Foundations',
        );
      }}
    >
      {Object.entries(exploreSections).map(([id, label]) => (
        <div
          key={id}
          style={css({
            gridArea: id,
            on: $ => [
              $('@container group (width < 44rem)', {
                display: 'none',
              }),
            ],
          })}
        >
          <Checkbox
            label={label}
            checked={selection[id as keyof typeof exploreSections]}
            onChange={e => {
              setSelection({ [id]: e.currentTarget.checked });
            }}
          />
        </div>
      ))}
      <div style={{ gridArea: 'x', placeSelf: 'center' }}>
        <Button size='lg' variant='primary'>
          <div style={{ display: 'inline-block', padding: '0 1rem', whiteSpace: 'nowrap' }}>
            Explore UDS
          </div>
        </Button>
      </div>
    </form>
  );
}

function Typography() {
  return (
    <span
      style={{
        fontStyle: 'italic',
        fontSize: '7.5em',
        letterSpacing: '-0.01em',
        fontWeight: 600,
        lineHeight: 1,
        color: V.primary101Brand,
        transform: 'translateY(0.17em)',
      }}
    >
      Aa
    </span>
  );
}

function Mascot() {
  const [tooltip, setTooltip] = useState(true);
  return (
    <div style={{ display: 'flex', alignItems: 'end', fontSize: '2.5cqmin' }}>
      <div style={{ alignSelf: 'center' }}>
        <Toggle checked={tooltip} onChange={setTooltip} />
      </div>
      <Typography />
      <div style={{ position: 'relative', marginLeft: '-1em', color: V.tertiary301Blue }}>
        <div style={{ opacity: tooltip ? 1 : 0, transition: 'opacity 150ms' }}>
          <div
            style={{
              position: 'absolute',
              top: '-2.625rem',
              right: 0,
              fontSize: '0.8125rem',
              fontWeight: 700,
              lineHeight: 1,
              padding: '0.5rem',
              background: 'currentColor',
              borderRadius: '0.25rem',
              whiteSpace: 'nowrap',
              boxShadow: '0px 4px 8px 0px rgba(3, 59, 105, 0.25)',
            }}
          >
            <span style={{ color: 'white' }}>Meet our mascot</span>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '-1.375rem',
              right: 'calc(50% - 0.75rem)',
              width: '1rem',
              height: '1rem',
              background: 'currentColor',
              transform: 'rotate(45deg)',
              boxShadow: '2px 2px 8px 0 rgba(3,59,105,0.25)',
              clipPath:
                'polygon(0 100%, 100% 0, calc(100% + 10px) 0, calc(100% + 10px) calc(100% + 10px), 0 calc(100% + 10px), 0 100%)',
            }}
          />
        </div>
        <img src={mascot} alt='UDS Mascot' style={{ width: '6em', height: '4.9375em' }} />
      </div>
    </div>
  );
}

export default function HeroCard(props: {
  selectoptions: Theme[];
  defaultValue?: Theme;
  handleSelectChange?: Dispatch<SetStateAction<Theme>>;
  currentValue?: Theme;
  fillColor?: string;
  onExploreSubmit?: (section: (typeof exploreSections)[keyof typeof exploreSections]) => void;
}) {
  const { navigateToURL } = useNavigation();

  return (
    <section data-testid='hero-container'>
      <HomeCardContainer>
        <HomeCardPadding>
          <HomeCardContent reverseColumns>
            <div style={{ containerType: 'inline-size', width: '100%' }}>
              <div
                style={css({
                  display: 'grid',
                  width: '100%',
                  height: '18.75rem',
                  on: $ => [
                    $('@container (width >= 44rem)', {
                      height: '25rem',
                    }),
                  ],
                })}
              >
                <HomeCardGraphicContainer>
                  <div
                    style={{
                      background: `url(${backgroundRight})`,
                      containerName: 'group',
                      containerType: 'inline-size',
                      display: 'grid',
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${backgroundLeft})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right 40cqw center',
                        backgroundSize: 'auto 100%',
                        padding: '2rem',
                        paddingBottom: 0,
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        justifyContent: 'flex-end',
                        alignContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <Mascot />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'end',
                        }}
                      >
                        <div style={{ display: 'grid', width: '12rem' }}>
                          <Select
                            placeholder='Choose a theme'
                            items={props.selectoptions}
                            value={props.currentValue}
                            itemText={th => `${titleCase(th)} Mode`}
                            onChange={props.handleSelectChange}
                          />
                        </div>
                      </div>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                        <Explore
                          onSubmit={section => {
                            props.onExploreSubmit?.(section);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </HomeCardGraphicContainer>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                maxWidth: '50rem',
                flexDirection: 'column',
                gap: '1.5rem',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <HomeCardText variant='heading1' render='h1'>
                  Welcome to the Unified Design System!
                </HomeCardText>
                <HomeCardText variant='paragraph' render='p'>
                  UDS is a comprehensive design framework that empowers Albertsons to create
                  consistent, engaging, and user-friendly experiences across platforms.
                </HomeCardText>
              </div>
              <div style={{ containerType: 'inline-size' }}>
                <div
                  style={css({
                    display: 'grid',
                    gap: '1rem',
                    on: ($, { not }) => [
                      $(not('@container (width < 32rem)'), {
                        gridTemplateColumns: '8.75rem 9.1875rem',
                      }),
                    ],
                  })}
                >
                  <div
                    className={clsx('inline-flex rounded-lg', {
                      'bg-[#BCDFFD] hover:bg-[#93CBFE] active:bg-[#7AB9F4]':
                        props.currentValue === Theme.dark,
                    })}
                  >
                    <Button
                      size='lg'
                      onClick={() => {
                        navigateToURL('/design');
                      }}
                      className={clsx('h-12 border-0', {
                        'hover:bg-[#124C81] active:bg-[#033B69]': props.currentValue !== Theme.dark,
                        'bg-transparent text-dark-text hover:bg-transparent focus:bg-transparent active:bg-transparent  border-transparent':
                          props.currentValue === Theme.dark,
                      })}
                    >
                      Start Designing
                    </Button>
                  </div>
                  <Button
                    variant='secondary'
                    size='lg'
                    onClick={() => {
                      navigateToURL('/development');
                    }}
                    className={clsx({
                      'hover:bg-transparent active:bg-transparent hover:text-[#124C81] active:text-[#033B69]':
                        props.currentValue !== Theme.dark,
                      'bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent text-[#BCDFFD] border-[#BCDFFD] focus:outline-white focus:text-[#BCDFFD] focus:border-[#BCDFFD] hover:text-[#93CBFE] focus:hover:text-[#93CBFE] hover:border-[#93CBFE] focus:hover:border-[#93CBFE] active:text-[#7AB9F4] active:border-[#7AB9F4] focus:active:text-[#7AB9F4] focus:active:border-[#7AB9F4]':
                        props.currentValue === Theme.dark,
                    })}
                  >
                    Start Developing
                  </Button>
                </div>
              </div>
            </div>
          </HomeCardContent>
        </HomeCardPadding>
      </HomeCardContainer>
    </section>
  );
}
